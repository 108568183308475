//@flow strict

import * as React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import Games from './games/Games';
import Under from './games/under/Under';
import Tempus from './games/tempus/Tempus';
import Cramped from './games/cramped/Cramped';
import Clue from './games/clue/Clue';		
import Colarity from './games/colarity/Colarity';
import PlayColarity from './games/colarity/PlayColarity';
import BCPC from './bcpc/BCPC';
import BCPC1 from './bcpc/bcpc1/BCPC1';
import BCPC2 from './bcpc/bcpc2/BCPC2';
import BCPC3 from './bcpc/bcpc3/BCPC3';
import Timestamps from './timestamps/Timestamps';
import PrivacyPolicy from './legalNonsense/PrivacyPolicy';
import ZeroLogin from './zero/ZeroLogin';
import CompletedJobRequestCreator from './zero/sound/CompletedJobRequestCreator';
import CFNotificationsLogin from './cfnotifications/CFNotificationsLogin';
import CompetitiveProgramming from './competitiveProgramming/CompetitiveProgramming';
import ProblemsIveWritten from './competitiveProgramming/ProblemsIveWritten';
import LGMSniper from './competitiveProgramming/LGMSniper';
import IawTest from './legalNonsense/IawTest';
import * as serviceWorker from './serviceWorker';
import ReactTooltip from 'react-tooltip';
import 'react-toastify/dist/ReactToastify.css';
import './Main.css';
import PointLineDuality from './competitiveProgramming/pointLineDuality/PointLineDuality';

const routing = (
	<Router>
		<div className="header">
			<ReactTooltip />
			<Switch>
				<Route exact path="/" component={App} />
				<Route exact path="/Zero" component={ZeroLogin} />
				<Route exact path="/Zero/CompletedJobRequestCreator" component={CompletedJobRequestCreator} />
				<Route exact path="/Timestamps" component={Timestamps} />
				<Route exact path="/CFNotifications" component={CFNotificationsLogin} />
				<Route exact path="/Games" component={Games} />
				<Route exact path="/Games/Under" component={Under} />
				<Route exact path="/Games/Tempus" component={Tempus} />
				<Route exact path="/Games/Cramped" component={Cramped} />
				<Route exact path="/Games/Clue" component={Clue} />
				<Route exact path="/Games/Colarity" component={Colarity} />
				<Route exact path="/Games/Colarity/Play" component={PlayColarity} />
				<Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />
				<Route exact path="/BCPC" component={BCPC} />
				<Route exact path="/BCPC/BCPC1" component={BCPC1} />
				<Route exact path="/BCPC/BCPC2" component={BCPC2} />
				<Route exact path="/BCPC/BCPC3" component={BCPC3} />
				<Route exact path="/CompetitiveProgramming" component={CompetitiveProgramming} />
				<Route exact path="/CompetitiveProgramming/PointLineDuality" component={PointLineDuality} />
				<Route exact path="/CompetitiveProgramming/ProblemsIveWritten" component={ProblemsIveWritten} />
				<Route exact path="/CompetitiveProgramming/LGMSniper" component={LGMSniper} />
				<Route exact path="/IawTest" component={IawTest} />
			</Switch>
		</div>
	</Router>
);

const rootComponent = document.getElementById('root');
if (rootComponent!=null) {
	ReactDOM.render(routing, rootComponent);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
