//@flow strict

import secondsToTime from '../secondsToTime';
type Contest = {
	phase: 'BEFORE' | 'SYSTEM_TEST' | 'CODING' | 'PENING_SYSTEM_TEST' | 'FINISHED',
	startTimeSeconds: number|null,
	name: string,
	type: 'CF' | 'IOI' | 'ICPC',
	relativeTimeSeconds: number,
};

function getUpcomingContests(onLoad: (thingToAnounce: string) => void): void {
	fetch('https://codeforces.com/api/contest.list').then(res => {
		res.json().then(data => {
			console.log('got contests: ');
			const dataResults: Array<Contest> = data.result;
			const result = dataResults.filter(
				(contest:any) => contest.phase === 'BEFORE' && 
				!contest.name.toLowerCase().includes('kotlin') && //skip kotlin heros
				!contest.name.toLowerCase().includes('q#') //skip microsoft q# contests
			);
			console.log(result);
			result.sort((a, b) => {
				return -a.relativeTimeSeconds - (-b.relativeTimeSeconds);
			});
			if (result.length === 0) {
				onLoad('There are no upcoming contests sir.');
			}
			else if (result.length >= 1) {
				const contest=result[0];
				onLoad(contest.name+' starts in '+secondsToTime(-contest.relativeTimeSeconds)+'.');
			}
		});
	});
}

export default getUpcomingContests;
