//@flow strict

import React, {useState} from 'react';
import WumboBanner from '../global/WumboBanner';
import ZeroAnimation from '../zero/animation/ZeroAnimation';
import '../Main.css';
import {
	initCFChecker, 
	checkForSubmissionsPublic
} from '../zero/codeforcesAPI/checkForNewSubmissions';
import {playVictorySound} from '../zero/videos/Victory';
import VideoPlayer from '../zero/videos/VideoPlayer';

import type {Submission} from '../zero/codeforcesAPI/getRecentSubmissions';

interface Props {
	handle: string,
	title: string,
	customSoundURL: string,
	useVoiceOnAC: boolean,
}

function getVoice(synth: SpeechSynthesis): SpeechSynthesisVoice {
	const allVoices=synth.getVoices();
	let selectedVoice = allVoices.find(v => v.name.startsWith('Microsoft Eva'));
	if (selectedVoice == null) {
		console.log('Using backup zira voice');
		selectedVoice = allVoices.find(v => v.name.startsWith('Microsoft Zira'));
	}
	if (selectedVoice == null) {
		console.log('Using backup eva voice');
		selectedVoice = allVoices.find(v => v.name.startsWith('Ava'));
	}
	if (selectedVoice == null) {
		console.log('Voice names: ');
		console.log(window.speechSynthesis.getVoices().map(v => v.name));
		console.log('Couldnt find correct voice!');
		selectedVoice = allVoices[0];
	}
	return selectedVoice;
}

function sayString(synth: SpeechSynthesis, toSay: string, afterSpeech?: () => void): void {
	const utterance = new SpeechSynthesisUtterance(toSay);
	utterance.voice=getVoice(synth);
	utterance.rate=1.1;
	synth.speak(utterance);
	const loop= () => {
		if (synth.speaking) {
			setTimeout(loop, 100);
		}
		else {
			if (afterSpeech != null) {
				afterSpeech();
			}
		}
	}
	setTimeout(loop, 100);
}

function cfLoop(props: Props, synth: SpeechSynthesis, setYoutubeURLToPlay: React.Dispatch<React.SetStateAction<string | null>>): void {
	checkForSubmissionsPublic(props.handle, (submission: Submission) => {
		console.log('Got a submission!');
		let toSay: string|null = null;
		const problemIndex: string = submission.problem.index;
		const verdict=submission.verdict ?? 'TESTING';
		const testsPassed=submission.passedTestCount;
		if (verdict === 'OK') {
			if (props.useVoiceOnAC) {
				toSay='Well done sir! AC on problem '+problemIndex+'.';
			}
			else {
				toSay='';
			}

			if (props.customSoundURL == null || props.customSoundURL==='') {
				playVictorySound();
			}
			else {
				if (props.customSoundURL === 'none') {
					//don't play sound
				}
				else {
					// stop and start the sound to restart the video
					// This might not actually work because it should be using setstatewithcallback, which
					// doesn't really exist yet
					setYoutubeURLToPlay(null);
					setYoutubeURLToPlay(props.customSoundURL);
				}
			}
		}
		else {
			const verdictAsString=verdict.replace(/_/g, ' ');
			toSay='Problem '+problemIndex+' got '+verdictAsString+' test '+(testsPassed+1)+' sir.';
		}
		sayString(synth, toSay);
	});
}

function CFNotificationsHome(props: Props) {

	const synth=window.speechSynthesis;
	const [youtubeURLToPlay, setYoutubeURLToPlay] = useState<string|null>(null);
	const screenDim=false;
	const haveCustomURL=props.customSoundURL !== '' && props.customSoundURL !== 'none';

	React.useEffect(() => {
		initCFChecker(props.handle);
		const id = setInterval(() => cfLoop(props, synth, setYoutubeURLToPlay), 1000);
		return () => clearInterval(id);
	}, [props, synth]);
	return (
		<div>
			{screenDim?<div></div>:<WumboBanner />}
			<div className="maintext black">
			<br className="clearAll"/>
				{screenDim?<div></div>:
					<ZeroAnimation 
						slow={false} 
						listening={false}
					/>
				}
				<br className="clearAll"/>
				{
					haveCustomURL?
					<div>
						<div className='largeBlock'> </div>
						<VideoPlayer 
							soundToPlay="STOP" 
							volume={100} 
							shouldUpdateURLs={false} 
							customURL={youtubeURLToPlay} 
						/>
					</div>
					:
					<div></div>
				}
			</div>
		</div>
	);

	
}

export default CFNotificationsHome;
