
import {useState} from 'react';
import WumboBanner from '../global/WumboBanner';
import {useLocation} from 'react-router-dom';
import '../Main.css';

type Problem = {
	index: string,
}

type Submission = {
	contestId: number,
	verdict: string,
	relativeTimeSeconds: number,
	problem: Problem,
};

const indexPrefixForSubmission = (submission: Submission) => submission.verdict==="OK"?"Solving ":"Attempting ";
const indexSuffixForSubmission = (submission: Submission) => submission.verdict==="OK"?"":" (Unfinished)";
const toTwoDigitString = (x: number) => (x<10?("0"+x):(""+x));

function getPrettyTime(relativeTimeSeconds: number): string {
	const seconds=relativeTimeSeconds%60;
	const minutesUnrounded=(relativeTimeSeconds-seconds)/60;
	const minutes=minutesUnrounded%60;
	const hours=(minutesUnrounded-minutes)/60;
	if (hours !== 0) {
		return hours+":"+toTwoDigitString(minutes)+":"+toTwoDigitString(seconds);
	}
	return minutes+":"+toTwoDigitString(seconds);
}

async function onClick(setTimestamps: (timestamps: string) => void, videoOffsetString: string, handle: string): Promise<void> {
	const response = await fetch('https://codeforces.com/api/user.status?handle='+handle+'&from=1&count=100');
	const json = await response.json();
	const result: Array<Submission> = json.result;
	if (result == null) {
		setTimestamps("handle not found. Check console for CF's response.");
		console.log(json);
		return;
	}
	const targetContestId = result[0].contestId;
	const submissions = result.filter(x => x.contestId === targetContestId).reverse();
	let videoOffset: number = parseInt(videoOffsetString);
	if (isNaN(videoOffset)) {
		videoOffset=0;
	}

	//only include the last submission to each problem
	const finalSubmissions: Array<Submission> = [];
	for (let i = submissions.length-1; i>=0; i--) {
		if (finalSubmissions.find(x => x.problem.index === submissions[i].problem.index) != null) {
			continue;
		}
		finalSubmissions.unshift(submissions[i]);
	}

	let finalString = 'Timestamps:\n';

	if (videoOffset>0) {
		finalString += '0:00 Goodmorning\n';
	}
	for (let i = 0; i<finalSubmissions.length; i++) {
		let time = i===0 ? videoOffset : (finalSubmissions[i-1].relativeTimeSeconds + videoOffset);
		time=Math.max(time, 0);
		const submission = finalSubmissions[i];
		const prettyTime = getPrettyTime(time);
		const prettyLabel = indexPrefixForSubmission(submission) + submission.problem.index + indexSuffixForSubmission(submission);
		finalString += prettyTime+" "+prettyLabel+"\n";
	}
	const finalTime = finalSubmissions[finalSubmissions.length-1].relativeTimeSeconds+videoOffset;
	finalString += getPrettyTime(finalTime)+" ???";
	

	setTimestamps(finalString);
}

function Timestamps() {
    const [timestamps, setTimestamps] = useState("");
	const [videoOffset, setVideoOffset] = useState("");
	const params=new URLSearchParams(useLocation().search);
	const handleParam = params.get('handle');
	const defaultHandle = handleParam==null?"SecondThread":handleParam;
	const [handle, setHandle] = useState(defaultHandle);
	

    return <div>
			<WumboBanner />
			<div className="maintext">
				<h1 className="title">Timestamps</h1>
				<div className="text-center">
					<button className="btn wumboprimary" onClick={() => onClick(setTimestamps, videoOffset, handle)}>Generate Timestamps</button>
				</div>

				<input className="form-control col-md-2 center mediumPadding"
					value={handle} 
					onChange={(e) => setHandle(e.target.value)} 
					placeholder="CF Handle"
					/>

				<input className="form-control col-md-3 center mediumPadding"
					value={videoOffset} 
					onChange={(e) => setVideoOffset(e.target.value)} 
					placeholder="Seconds in video before contest"
					/>

				<textarea 
					className="form-control rounded-0 col-md-6 center mediumPadding"
					id="exampleFormControlTextarea1" 
					rows={10} 
					value={timestamps} 
					onChange={(e) => setTimestamps(e.target.value)}
					placeholder="Click button to generate"
					/>
			</div>
		</div>;
}

export default Timestamps;