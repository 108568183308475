//@flow strict

import * as React from 'react';
import WumboBanner from '../../global/WumboBanner';
import Navbar from '../../global/Navbar';
import PictureAndDescription from '../../global/PictureAndDescription';
import UnderTitleScreen from './undertitlescreen.png';
import UnderScreenshot1 from './underscreenshot1.png';
import UnderScreenshot2 from './underscreenshot2.png';
import UnderScreenshot3 from './underscreenshot3.png';
import UnderScreenshot4 from './underscreenshot4.png';
import UnderScreenshot5 from './underscreenshot5.png';
import postSiteVisit from '../../global/postSiteVisit';
import '../../Main.css';


const GAME_DOWNLOAD = '/games/under/under.jar';

function Under(): JSX.Element {

	React.useEffect(() => postSiteVisit('/games/Under'), []);

	return (
		<div>
			<WumboBanner />
			<div className="maintext">
				<Navbar />
				<PictureAndDescription imageSrc={UnderTitleScreen} imageDownload={GAME_DOWNLOAD}>
					Under-Life of a Frog is the first game made in Java by WiErD0 Games (now Wumbo Games),
					made during <a href="http://ludumdare.com/compo/ludum-dare-29/">Ludum Dare 29</a> for 
					the theme "Beneath the Surface".
					In Under, you are a frog that has to survive, while getting as many points as possible.
					With infinite, randomly generated terrain, you can explore the depths of the sea or the tallest
					mountains to find treasure, or stick to smaller islands and eat flies for food.
					Good luck and don't drown! : )
				</PictureAndDescription>
				<div className="indented">
					<a href={GAME_DOWNLOAD} title="Download">Download Here!</a>
					<br />
					<br />
					<h2 className="title"><u>Controls:</u></h2>
					<p className="normal">Turn: A/D</p>
					<p className="normal">Jump off an island: Hold space</p>
					<p className="normal">Swim up: Tap space</p>
					<p className="normal">Swim down: Hold S, Tap space</p>
					<br />
					<h2 className="title"><u>Screenshots:</u></h2>	
					<img src={UnderScreenshot1} width="80%" className="center" alt="" />
					<div className="photolist"><img src={UnderScreenshot5} width="400px" className="center" alt="" /></div>
					<div className="photolistright"><img src={UnderScreenshot2} width="400px" className="center" alt="" /></div>
					<br className="clearAll" />
					<div className="photolist"><img src={UnderScreenshot3} width="400px" className="center" alt="" /></div>
					<div className="photolistright"><img src={UnderScreenshot4} width="400px" className="center" alt="" /></div>
					<br className="clearAll"/>
				</div>
			</div>
		</div>
	);

}

export default Under;
