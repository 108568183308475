//@flow strict

type Props = {
    selected: boolean;
    color: string;
    onClick: () => void;
};

function ColorButton(props: Props) {
    const {selected, color, onClick} = props;
    return  <div className={selected?"darkGreyBackground":"lightGreyBackground"} onClick={onClick}>
                <div className={color}></div>
            </div>;
}

export default ColorButton;