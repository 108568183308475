//@flow strict

type Problem = {
	index: string, //'A', 'B', 'C' ...
	points: number,
	rating?: number,
	name: string,
}

type Verdict = 'FAILED' | 'OK' | 'PARTIAL' | 'COMPILATION_ERROR' | 'RUNTIME_ERROR' | 'WRONG_ANSWER' | 'PRESENTATION_ERROR' | 'TIME_LIMIT_EXCEEDED' | 'MEMORY_LIMIT_EXCEEDED' | 'IDLENESS_LIMIT_EXCEEDED' | 'SECURITY_VIOLATED' | 'CRASHED' | 'INPUT_PREPARATION_CRASHED' | 'CHALLENGED' | 'SKIPPED' | 'TESTING' | 'REJECTED';

type Submission = {
	id: number,
	verdict?: Verdict,
	name: string,
	index: string,
	problem: Problem,
	passedTestCount: number,
};

function getRecentSubmissions(
	handle: string, 
	nSubmissions: number,
	onLoad: (submissions: Array<Submission>) => void,
): void {
	fetch('https://codeforces.com/api/user.status?handle='+handle+'&from=1&count='+nSubmissions).then(res => {
		res.json().then(data => {
			const submissions: Submission[] = data.result;
			onLoad(submissions);
		});
	});
}

export type {Verdict, Submission};

export default getRecentSubmissions;

