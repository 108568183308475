//@flow strict

import * as React from 'react';
import WumboBanner from '../global/WumboBanner';
import Navbar from '../global/Navbar';
import {Link} from 'react-router-dom';
import postSiteVisit from '../global/postSiteVisit';
import '../Main.css';


function BCPC() {
	React.useEffect(() => postSiteVisit('/BCPC'), []);

	return (
		<div>
			<WumboBanner />
			<div className="maintext">
				<Navbar />
				<h1 className="title">Brookfield Computer Programming Challenge</h1>
				<p>
					The Brookfield Computer Programming Challenge is an annual high-school programming contest hosted by
					the Elmbrook School District. Schools across Wisconsin send their brightest and most talented
					students to solve challenging algorithmic problems. Problem sets, data, solutions, and editorials
					for past contests are all available here.
				</p>
				<h3>
					<ul className="normalList">
						<li><Link to="/BCPC/BCPC1">BCPC 1</Link></li>
						<li><Link to="/BCPC/BCPC2">BCPC 2</Link></li>
						<li><Link to="/BCPC/BCPC3">BCPC 3</Link></li>
					</ul>
				</h3>
			</div>
		</div>
	);
}

export default BCPC;
