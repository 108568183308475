//@flow strict

import crypto from 'crypto';

const CORRECT_HASH = 'a9c54d2dc7802c85e4b1b8318a4d5a9d';

//returns true if the password is okay, false otherwise
function verifyPassword(password: string): boolean {
	if (password==null) return false;
	const hash = crypto.createHash('md5').update(password).digest('hex');
	console.log('Hash is '+hash);
	return hash === CORRECT_HASH;
}

export default verifyPassword;
