//@flow strict

function getJokeFromAPI(onLoad: (jokeToSay: string) => void): void {
	fetch('https://icanhazdadjoke.com/',{
		headers: {
			'Accept': 'application/json',
		},
	}).then(res => {
		if (res.status !== 200) {
			console.log('Error getting joke!');
			console.log(res.status);
			console.log(res);
		}
		else {
			res.json().then(data => {
				const joke=data.joke;
				onLoad(joke);
			});
		}
	});
}

export default getJokeFromAPI;
