//@flow strict

function createPing(password: string): void {
	fetch('/api/zero/ping', {
		method: 'POST',
		headers: {
			'Access-Control-Allow-Origin': '*',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			password: password
		}),
	}).then(res => {
		console.log('Created ping request, result: ');
		console.log(res);
	});
}

export default createPing;