
//@flow strict

//$FlowFixMe flow doesn't see this file for some reason...
const reloadMP3= require('./reload.mp3');

const audio=new Audio(reloadMP3.default);

function playReloadSound() {
	audio.play();
}

export {playReloadSound};
