//@flow strict

import {useState} from 'react';
import WumboBanner from '../global/WumboBanner';
import ZeroHome from './ZeroHome';
import getCurrentLocation from './weather/getCurrentLocation';
import verifyPassword from './verifyPassword';
import {toast} from 'react-toastify';
import '../Main.css';

function tryLogin(enteredPassword: string, setPassword: React.Dispatch<React.SetStateAction<string|null>>) {
	console.log('Trying password: ');
	console.log(enteredPassword);
	if (verifyPassword(enteredPassword)) {
		setPassword(enteredPassword);
	}
	else {
		toast.error('Incorrect password');
	}
}

function ZeroLogin() {
	getCurrentLocation(coords => console.log('requested location access'));
	const [password, setPassword] = useState<string|null>(null);
	const [enteredPassword, setEnteredPassword] = useState('');
	if (password == null) {
		return (
			<div>
				<WumboBanner />
				<div className="maintext">
					<h1 className="title">Zero Login</h1>
					<div className="col-md-3 center form-group">
						<input 
							type="text" 
							className="form-control"
						/>
					</div>
					<div className="col-md-3 center form-group">
						<input 
							type="password"
							className="form-control"
							placeholder="password"
							onChange={e => setEnteredPassword(e.target.value)}
						/>
					</div>
					<div className="text-center">
						<button onClick={() => tryLogin(enteredPassword, setPassword)} className="btn wumboprimary">Login</button>
					</div>
				</div>
			</div>
		);
	}
	return (
		<ZeroHome password={password} />
	);
}

export default ZeroLogin;
