//@flow strict

import * as React from 'react';
import  {useRef, useEffect} from 'react';

import type {ColorType} from './ColorType';

type Vec = {
    x: number;
    y: number;
    pointInLeft?: boolean;
}

type Props = {
    selectedColor: ColorType;
    isLeft: boolean;
    redPos: Vec|null; setRedPos: React.Dispatch<React.SetStateAction<Vec | null>>;
    bluePos: Vec|null; setBluePos: React.Dispatch<React.SetStateAction<Vec | null>>;
    greenPos: Vec|null, setGreenPos: React.Dispatch<React.SetStateAction<Vec | null>>;
    pinkPos: Vec|null, setPinkPos: React.Dispatch<React.SetStateAction<Vec | null>>;
    purplePos: Vec|null, setPurplePos: React.Dispatch<React.SetStateAction<Vec | null>>;
};



const WIDTH=5;
const CANVAS_WIDTH=500;
const POINT_WIDTH=4;

function canvasToPlane(onCanvas: Vec): Vec {
    const newX=onCanvas.x/CANVAS_WIDTH*WIDTH-WIDTH/2;
    const newY=-(onCanvas.y/CANVAS_WIDTH*WIDTH-WIDTH/2);
    return {x: newX, y:newY, pointInLeft: onCanvas.pointInLeft};
}

function planeToCanvas(onPlane: Vec): Vec {
    const newX=(onPlane.x + WIDTH/2)/WIDTH*CANVAS_WIDTH;
    const newY=(-onPlane.y + WIDTH/2)/WIDTH*CANVAS_WIDTH;
    return {x: newX, y:newY, pointInLeft: onPlane.pointInLeft};
} 

function draw(context: CanvasRenderingContext2D, pos: Vec|null, color: string, amLeft: boolean) {
    if (pos==null) return;
    if (amLeft === pos.pointInLeft) {
        context.fillStyle=color;
        context.beginPath();
        const onCanvas = planeToCanvas(pos);
        context.ellipse(onCanvas.x, onCanvas.y, POINT_WIDTH, POINT_WIDTH, 0, 0, 2*Math.PI);
        context.fill();
    }
    else {
        //otherwise I am a line here
        context.strokeStyle=color;
        const m=pos.x, b=-pos.y;
        const x1=-WIDTH/2, x2=WIDTH/2;
        const y1=m*x1+b, y2=m*x2+b;
        const startLine=planeToCanvas({x: x1, y: y1}), endLine=planeToCanvas({x: x2, y:y2});
        context.beginPath();
        context.moveTo(startLine.x, startLine.y);
        context.lineTo(endLine.x, endLine.y);
        context.stroke();
    }
}

function Plot(props: Props) {
    const {selectedColor, isLeft, redPos, setRedPos, bluePos, setBluePos, greenPos, setGreenPos, pinkPos, setPinkPos, purplePos, setPurplePos} = props;

    const canvasRef = useRef<HTMLCanvasElement>(null);

    //ADD CLICK LISTENER USEEFFECT
    useEffect(() => {
        const canvas: HTMLCanvasElement|null=canvasRef.current;
        if (canvas == null) {
            return;
        }
        const handleClick = (e: MouseEvent) => {
            const rect = canvas.getBoundingClientRect(); 
            const x = e.clientX - rect.left; 
            const y = e.clientY - rect.top;
            const pointInLeft=isLeft;
            const convertedPos=canvasToPlane({x, y, pointInLeft});
            switch (selectedColor) {
                case 'roundedRedBox':
                    setRedPos(convertedPos);
                    break;
                case 'roundedGreenBox':
                    setGreenPos(convertedPos);
                    break;
                case 'roundedBlueBox':
                    setBluePos(convertedPos);
                    break;
                case 'roundedPinkBox':
                    setPinkPos(convertedPos);
                    break;
                case 'roundedPurpleBox':
                    setPurplePos(convertedPos);
                    break;
                default:
                    console.log('MISSING COLOR IN SWITCH STATEMENT');
            }                  
        };

        canvas.addEventListener('mousedown', handleClick);
        return () => {
            canvas.removeEventListener('mousedown', handleClick);
        };
    }, [selectedColor, isLeft, setBluePos, setGreenPos, setPurplePos, setRedPos, setPinkPos]);

    //RENDER USEEFFECT
    useEffect(() => {
        if (canvasRef == null || canvasRef.current==null) return;

        const context =canvasRef.current.getContext('2d');
        if (context==null) return;
        context.fillStyle="white";
        context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_WIDTH);
        context.strokeStyle="black";
        const startBottom = planeToCanvas({x: 0, y: -WIDTH});
        const startTop = planeToCanvas({x: 0, y: WIDTH});
        const startLeft = planeToCanvas({x: -WIDTH, y: 0});
        const startRight = planeToCanvas({x: WIDTH, y: 0});
        context.beginPath();
        context.moveTo(startLeft.x, startLeft.y);
        context.lineTo(startRight.x, startRight.y);
        context.stroke();
        context.beginPath();
        context.moveTo(startBottom.x, startBottom.y);
        context.lineTo(startTop.x, startTop.y);
        context.stroke();
        
        
        draw(context, redPos, 'red', isLeft);
        draw(context, bluePos, 'blue', isLeft);
        draw(context, greenPos, 'green', isLeft);
        draw(context, pinkPos, 'pink', isLeft);
        draw(context, purplePos, 'purple', isLeft);

    });

    return <canvas className="canvasSize" ref={canvasRef} width={CANVAS_WIDTH} height={CANVAS_WIDTH} />
}

export default Plot;