//@flow strict

import JobRequest from '../jobs/JobRequest';

function getJobRequests(password: string, onLoad: (requests: Array<JobRequest>) => void): void {
	fetch('/api/zero/getJobRequests', {
		method: 'POST',
		headers: {
			'Access-Control-Allow-Origin': '*',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			password: password,
		}),
	}).then(res => {
		res.json().then(data => {
			const mapped= data.map((x: any) => {return {
				jobType: x.jobType,
				activationTime: new Date(x.activationTime),
				abandonTime: new Date(x.abandonTime),
				jobInfo: JSON.parse(x.jobInfoJSON),
				_id: x._id,
			};});
			
			for (const job of mapped) {
				if (job.jobInfo.timeOfQuery!=null) {
					job.jobInfo.timeOfQuery=new Date(job.jobInfo.timeOfQuery);
				}
			}
			onLoad(mapped);
		})
	});
}

export default getJobRequests;
