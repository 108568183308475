//@flow strict

import {toast} from 'react-toastify';

function postSiteVisit(site: string): void {
	fetch('/api/analytics/postSiteVisit', {
		method: 'POST',
		headers: {
			'Access-Control-Allow-Origin': '*',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			siteVisited: site
		}),
	}).then(res => {
		if (res.status!==200) {
			toast.error("Couldn't connect to Wumbo Games server...");
			console.log('Analytics call failed!');
			console.log(res.status);
			console.log(res);
		}
		else {
			console.log('analytics save succeeded.');
		}
	});
}

export default postSiteVisit;
