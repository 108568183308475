//@flow strict

function secondsToTime(secondsUnrounded: number): string {
	const seconds=Math.round(secondsUnrounded);
	if (seconds<100) {
		return seconds+' second'+(seconds!==1?'s':'');
	}
	else if (seconds<60*60) {
		const minutes=Math.round(seconds/60);
		return minutes+' minute'+(minutes!==1?'s':'');
	}
	else if (seconds<60*60*24) {
		const hours=Math.floor(seconds/(60*60));
		const minutes=Math.round(seconds/60%60);
		return hours+' hour'+(hours!==1?'s':'')+' '+minutes+' minute'+(minutes!==1?'s':'');
	}
	else {
		const aDay=60*60*24;
		const days=Math.floor(seconds/aDay);
		const hours=Math.floor((seconds-days*aDay)/(60*60));
		return days+' day'+(days!==1?'s':'')+' '+hours+' hour'+(hours!==1?'s':'');
	}
}

export default secondsToTime;
