//@flow strict

import JobRequest from './jobs/JobRequest';
import createJobRequest from './apiRequests/createJobRequest';
import parseStringForTime from './parseStringForTime';
import secondsToTime from './secondsToTime';
import {onTimerSnooze, onTimerCompleted} from './tryAnsweringRequests';
import {stringToNumber} from './parseStringForTime';
import inTwoHours from './jobs/inTwoHours';
import inTwoMinutes from './jobs/inTwoMinutes';

import type {TextEvaluationResult} from './TextEvaluationResult';
import type {JobInfo} from './jobs/JobInfo';
import type {Sounds} from './videos/Sounds';
import type {ProjectType} from './jobs/ProjectType';


function idkWhatToDo(): void {
	console.log('IDK what to do and I havent handled this yet!');
	return;
}

function handleCFSchedule(password: string) {
	const job=new JobRequest('cf_schedule', new Date(), inTwoHours(), {});
	createJobRequest(job, password);
}

function handleCFPrep(
	evaluation: TextEvaluationResult,
	password: string,
	sayString: (toSay: string) => void,
): void {

	const entities: any=evaluation.entities;
	let project: ProjectType|null=null;
	const ent=entities['project_type:project_type'];
	if (ent != null) {
		const projectTypeString=ent[0].value;
		const projectTypeMap: any = {
			games: 'WUMBO_GAMES',
			fortuna: 'FORTUNA',
			fotuna: 'FORTUNA',
			'for tuna': 'FORTUNA',
			'for tune a': 'FORTUNA',
			'for turn on': 'FORTUNA',
			website: 'WUMBO_GAMES',
			contest: 'CODEFORCES',
			round: 'CODEFORCES',
			div: 'CODEFORCES',
			eclipse: 'CODEFORCES',
		};
		project = projectTypeString == null ? null : projectTypeMap[projectTypeString];
		if (project==null) {
			sayString("I parsed the project type, but I don't know what project it is for.");
			console.log('COULDNT MAP PROJECT TYPE STRING: '+projectTypeString);
			return;
		}
	}
	else {
		sayString("I don't know what project to prepare.");
		return;
	}
	const jobInfo: JobInfo = {projectType: project};
	const job=new JobRequest('cf_prep', new Date(), inTwoHours(), jobInfo);
	createJobRequest(job, password);
	if (project === 'WUMBO_GAMES' ) {
		sayString('Opening Wumbo Games sir.');
	}
	else if (project === 'FORTUNA') {
		sayString('Starting Fortuna commander.');
	}
	else {
		sayString('Preparing your computer for the contest sir.');
	}
}

function handleTimer(
	evaluation: TextEvaluationResult, 
	password: string,
	sayString: (toSay: string) => void,
): void {
	const entities: any=evaluation.entities;
	let time: Date = new Date();
	let jobInfo={};
	if (entities['wit$datetime:datetime'] != null) {
		const ent=entities['wit$datetime:datetime'][0];
		console.log(ent);
		if (ent.value != null) {
			time=new Date(ent.value);
			if (time.getHours() === 0 && time.getMinutes() === 0 && 
					time.getSeconds() === 0) {
				//assume we just care about the day, rather than the actual time
				//this might result in us getting things wrong at midnight though...
				//
				//remind at 7AM:
				time.setHours(7);	
			}
		}
	}
	else if (entities['timer_length:timer_length'] != null) {
		const ent=entities['timer_length:timer_length'][0];
		if (ent.value!=null) {
			const value=ent.value;
			time=parseStringForTime(value);
		}
	}
	else {
		console.log('Dont know how long to set the timer for!');
		sayString("I'm not sure how long to set it for");
		return;
	}

	if (entities['reminder_value:reminder_value'] != null) {
		const ent=entities['reminder_value:reminder_value'][0];
		if (ent.value != null) {
			//replace I/me with you and my with your
			let words: Array<String> = ent.value.split(' ');
			words=words.map(word => {
				if (word.toLowerCase() === 'i' || word.toLowerCase() === 'me') {
					return 'you';
				}
				else if (word.toLowerCase() === 'my') {
					return 'your';
				}
				else {
					return word;
				}
			});
			const reminderValue=words.join(' ');
			jobInfo={reminderValue: reminderValue};
		}
	}

	const dSeconds=(time.getTime()-Date.now())/1000;
	sayString("I'll let you know in "+secondsToTime(dSeconds)+'.');

	//hold reminders for 30 days
	const later=new Date(time.getTime()+60*60*24*30*1000);
	const job=new JobRequest('timer', time, later, jobInfo);
	createJobRequest(job, password);
}

function handleMusic(
	evaluation: TextEvaluationResult, 
	password: string,
	sayString: (toSay: string) => void,
): void {
	//might need to ask what type of music if we don't know
	const entities: any=evaluation.entities;
	if (entities['music_type:music_type'] == null) {
		sayString('I dont understand what type of music');
		return;
	}
	const ent = entities['music_type:music_type'][0];
	const possibleMusicType=ent.value;
	const musicTypeMap: any = {
		swing: 'SWING_MUSIC',
		working: 'WORKING_MUSIC',
		electroswing: 'SWING_MUSIC',
		stop: 'STOP',
		cut: 'STOP',
		turn_off: 'STOP',
		'turn off': 'STOP',
		quiet: 'STOP',
		enough: 'STOP',
		white_noise: 'WHITE_NOISE',
		'white noise': 'WHITE_NOISE',
		rain_noise: 'RAIN_NOISE',
		'rain nose': 'WHITE_NOISE',
		rain: 'RAIN_NOISE',
		christmas: 'CHRISTMAS',
		needle: 'CHRISTMAS',
		chiptune: 'CHIPTUNES',
		'video game': 'CHIPTUNES',
		bedtime: 'PIANO',
		piano: 'PIANO',
		chrissy: 'PIANO',
		"chrissy's": 'PIANO',
		"chrissy's bedtime": 'PIANO',
	}
	const mappedMusic: Sounds | undefined = musicTypeMap[possibleMusicType];
	if (mappedMusic == null) {
		console.log('DIDNT RECOGNIZE '+possibleMusicType);
		sayString('I parsed the type of music, but i dont know to play it');
		return;
	}
	const jobInfo: JobInfo={musicType: mappedMusic};
	const job=new JobRequest('music', new Date(), inTwoHours(), jobInfo);
	createJobRequest(job, password);
}

function handleJoke(password: string): void {
	const job=new JobRequest('joke', new Date(), inTwoHours(), {});
	createJobRequest(job, password);
}

function handleWeather(evaluation: TextEvaluationResult, password: string): void {
	let jobInfo: JobInfo = {};
	const entities: any=evaluation.entities;
	if (entities['wit$datetime:datetime'] != null) {
		const ent=entities['wit$datetime:datetime'][0];
		if (ent.value != null) {
			const timeOfQuery=new Date(ent.value);
			jobInfo={timeOfQuery: timeOfQuery};
		}
	}
	const job=new JobRequest('weather', new Date(), inTwoHours(), jobInfo);
	createJobRequest(job, password);
}

function handleAwakeCheck(password: string) {
	const job=new JobRequest('awake_check', new Date(), inTwoMinutes(), {});
	createJobRequest(job, password);
}

function handleStartListening(password: string) {
	const job=new JobRequest('start_listening', new Date(), inTwoMinutes(), {});
	createJobRequest(job, password);
}

function handleStopListening(password: string) {
	const job=new JobRequest('stop_listening', new Date(), inTwoMinutes(), {});
	createJobRequest(job, password);
}

function handleTimeCheck(password: string) {
	const job=new JobRequest('time_check', new Date(), inTwoMinutes(), {});
	createJobRequest(job, password);
}

function handleScreenDim(password: string) {
	const job=new JobRequest('screen_dim', new Date(), inTwoMinutes(), {});
	createJobRequest(job, password);
}

function handleVolume(
	evaluation: TextEvaluationResult, 
	password: string, 
	sayString: (toSay: string) => void,
	currentVolume: number,
): void {
	const entities: any=evaluation.entities;
	if (entities['volume_amount:volume_amount'] == null) {
		sayString('I dont understand how to change the volume.');
		return;
	}
	const volumeValue = entities['volume_amount:volume_amount'][0].value;
	let hasIncrease: boolean = false;
	let hasDecrease: boolean = false;
	let numberGiven: number = 0;
	for (const word of volumeValue.split(' ')) {
		if (word.toLowerCase().includes('up') || word.toLowerCase().includes('increase') ||
			word.toLowerCase().includes('louder')) {
			hasIncrease=true;
		}
		else if (word.toLowerCase().includes('down') || word.toLowerCase().includes('decrease') ||
			word.toLowerCase().includes('quieter')) {
			hasDecrease=true;
		}
		else {
			const asNumber=stringToNumber(word);
			if (asNumber !== 0) {
				numberGiven=asNumber;
			}
		}
	}
	let targetVolume: number|null = null;
	const goldenRatio=1.618;
	if (hasIncrease) {
		targetVolume=Math.min(100, currentVolume*goldenRatio);
	}
	else if (hasDecrease) {
		targetVolume=Math.min(100, currentVolume/goldenRatio);
	}
	else if (numberGiven !== 0) {
		targetVolume=numberGiven;
	}
	if (targetVolume == null) {
		sayString('I dont understand the volume change '+volumeValue+'.');
		return;
	}
	const jobInfo = {newVolume: targetVolume};
	const job=new JobRequest('volume', new Date(), inTwoMinutes(), jobInfo);
	createJobRequest(job, password);
}


function handleTextEvaluation(
	evaluation: TextEvaluationResult, 
	password: string,
	isListening: boolean,
	sayString: (toSay: string) => void,
	currentVolume: number,
): void {
	if (evaluation==null || evaluation.intents == null) {
		console.log('not a real evaluation...');
		return;
	}
	console.log('turning evalution into job:');
	console.log(evaluation);
	if (evaluation.intents.length === 0) {
		idkWhatToDo();
		return;
	}
	const intent=evaluation.intents[0].name;
	if (!isListening && intent !== 'start_listening' && intent !== 'timer_completed') {
		console.log('Skipping request because we arent listening');
		return;
	}
	switch(intent) {
		case 'cf_schedule': 
			handleCFSchedule(password);
			return;
		case 'cf_prep': 
			handleCFPrep(evaluation, password, sayString);
			return;
		case 'timer': 
			handleTimer(evaluation, password, sayString);
			return;
		case 'music': 
			handleMusic(evaluation, password, sayString);
			return;
		case 'joke': 
			handleJoke(password);
			return;
		case 'weather': 
			handleWeather(evaluation, password);
			return;
		case 'timer_snooze':
			onTimerSnooze(evaluation, password, sayString);
			return;
		case 'timer_completed':
			onTimerCompleted(evaluation, password);
			return;
		case 'awake_check':
			handleAwakeCheck(password);
			return;
		case 'start_listening':
			handleStartListening(password);
			return;
		case 'stop_listening':
			handleStopListening(password);
			return;
		case 'time_check':
			handleTimeCheck(password);
			return;
		case 'screen_dim':
			handleScreenDim(password);
			return;
		case 'volume':
			handleVolume(evaluation, password, sayString, currentVolume);
			break;
		default:
			throw new Error('Unexpected intent: '+intent);
	}
}

export default handleTextEvaluation;
