
//@flow strict

//$FlowFixMe flow doesn't see this file for some reason...
const victoryMP3= require('./victory.mp3');

const audio=new Audio(victoryMP3.default);

function playVictorySound() {
	audio.play();
}

export {playVictorySound};
