//@flow strict

type GeolocationCoordinates = {
	latitude: number,
	longitude: number,
};

function getCurrentLocation(onLoad: (coords: GeolocationCoordinates) => void): void {
	navigator.geolocation.getCurrentPosition(coords => onLoad(coords.coords), error => {
		console.log('Error getting location!');
		console.log(error);
	});
}

export default getCurrentLocation;
