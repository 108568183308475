//@flow strict

import * as React from 'react';
import WumboBanner from '../../global/WumboBanner';
import Navbar from '../../global/Navbar';
import FinalScores from './FinalScores.png';
import PictureAndDescription from '../../global/PictureAndDescription';
import VideoAndDescription from '../../global/VideoAndDescription';
import postSiteVisit from '../../global/postSiteVisit';
import '../../Main.css';

function BCPC2() {
	React.useEffect(() => postSiteVisit('/BCPC/BCPC2'), [])
	return (
		<div>
			<WumboBanner />
			<div className="maintext">
				<Navbar />
				<h1 className="title">2nd Annual Brookfield Computer Programming Challenge</h1>
				<p>
					Congrats to all the teams who competed this year! Problem sets, an analysis of the 
					problems, problem solution code, solution videos, and final scores are all available here.
				</p>
				<ul className="normalList">
					<li> <a href="/BCPC/BCPC2/BCPC2Problems.pdf" title="Download">2018 Problem Set download</a></li>
					<li> <a href="/BCPC/BCPC2/BonusProblem_TicTacs.pdf" title="Download">2018 Bonus Problem download</a></li>
					<li> <a href="/BCPC/BCPC2/ProblemsEditorial.pdf" title="Download">2018 Problem Analysis</a></li>
					<li> <a href="/BCPC/BCPC2/dataAndSolutions.zip" title="Download">2018 Data and Solutions</a></li>
				</ul>

				<br className="clearAll"/>

				<h2 className="title centeredText" >Final Standings</h2>
				<PictureAndDescription imageSrc={FinalScores}>
					Congradulations to Homestead for winning (solving the entire set!), Whitefish Bay for 
					placing second, and Madison West for placing third.  Also, congrats to all teams for
					being able to solve at least one problem!
					<p>
						For those who were wondering, penalty points
						are scored in the typical ACM-ICPC style. Teams get 1 penalty point per minute it takes
						them to solve a problem they end up solving, plus 20 penalty points per incorrect submission
						on a problem they end up solving. Problems a team does not solve do not contribute to their
						score at all. The winner is the team that solves the most problems. If there is a tie here,
						the winner is the team with fewest penalty points.
					</p>
				</PictureAndDescription>

				<br className="clearAll"/>

				<h2 className="title centeredText">Solution Videos</h2>
				<VideoAndDescription videoLink="https://www.youtube.com/embed/iPw1zxZKp-E">
					Here is a video containing a walk-through of how to solve all the problems from this 
					year's set which might be helpful to watch, if there was a problem you wanted to solve
					but couldn't figure out how to. If you have any comments, questions, concerns, or
					recommendations for next year, please email me at 13dharmeyer@gmail.com.
				</VideoAndDescription>
			</div>
		</div>
	);
}

export default BCPC2;
