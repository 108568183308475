import * as React from 'react';

import Unity, { UnityContext } from "react-unity-webgl";
import WumboBanner from '../../global/WumboBanner';
import '../../Main.css';


/*
TODO: test this to make sure all of these paths match up

Try re-exporting the colarity project from unity to webgl to see if that gives me new data
*/
const unityContext = new UnityContext({
	loaderUrl: "/Colarity/Build/ColarityWebGLBuild.loader.js",
	dataUrl: "/Colarity/Build/ColarityWebGLBuild.data",
	frameworkUrl: "/Colarity/Build/ColarityWebGLBuild.framework.js",
	codeUrl: "/Colarity/Build/ColarityWebGLBuild.wasm",
});

function PlayColarity(): JSX.Element {
	const style = {
		width: "80%",
		height: "50%",
	}
	return (
		<div>
			<WumboBanner />
			<Unity unityContext={unityContext} style={style} className="center" />
		</div>
	);
}

export default PlayColarity;
