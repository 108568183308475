//@flow strict

import * as React from 'react';
import {useState, useEffect} from 'react';
import WumboBanner from '../global/WumboBanner';
import CFNotificationsHome from './CFNotificationsHome';
import {toast} from 'react-toastify';
import postSiteVisit from '../global/postSiteVisit';
import Cookies from 'universal-cookie';

import '../Main.css';

function tryLogin(handle: string|null, customSoundUrl: string|null, useVoiceOnAC: boolean, setLoginClicked: (loginClicked: boolean) => void): void {
	const cookie=new Cookies();
	cookie.set('cfNotificationsHandle', handle??'');
	cookie.set('cfNotificationsCustomURL', customSoundUrl??'');
	cookie.set('cfNotificationsUseVoiceOnAC', useVoiceOnAC?'true':'false');
	setLoginClicked(true);
	toast.success('Waiting for submissions from '+(handle));
}

function CFNotificationsLogin() {

	const cookie=new Cookies();
	const useVoice=cookie.get('cfNotificationsUseVoiceOnAC')??'true';
	const [handle, setHandle] = useState(cookie.get('cfNotificationsHandle')??'');
	const [customSoundURL, setCustomSoundURL] = useState(cookie.get('cfNotificationsCustomURL'??''));
	const [loginClicked, setLoginClicked] = useState(false);
	const [useVoiceOnAC, setUseVoiceOnAC] = useState(useVoice==='true');

	useEffect(() => postSiteVisit('/cfnotifications'), []);

	//TODO: port try login, change set state calls to use variables above

	if (loginClicked) {
		return (
			<CFNotificationsHome 
				handle={handle} 
				title="sir" 
				customSoundURL={customSoundURL} 
				useVoiceOnAC={useVoiceOnAC}
			/>
		);
	}
	return (
		<div>
			<WumboBanner />
			<div className="maintext">
				<h1 className="title">CF Notifications</h1>
				<div className="col-md-3 center form-group">
					<input 
						type="text" 
						value={handle}
						onChange={e=>setHandle(e.target.value)}
						className="form-control"
						placeholder="handle"
					/>
				</div>
				<div className="col-md-3 center form-group">
					<input 
						type="text" 
						value={customSoundURL}
						onChange={e=>setCustomSoundURL(e.target.value)}
						className="form-control"
						placeholder="Custom Sound Youtube URL"
						data-tip="Optional: Enter a custom youtube URL for a sound to play when you solve a problem. Use string 'none' for no sound, or leave blank for default."
					/>
				</div>
				<div className="col-sm-3 center form-group">
					<label>
						Announce ACs with voice?
						<input 
							type="checkbox" 
							className="rightCentered"
							defaultChecked={useVoiceOnAC} 
							onChange={e=>setUseVoiceOnAC(e.currentTarget.checked)}
						/>
					</label>
				</div>
				<div className="text-center">
					<button onClick={() => tryLogin(handle, customSoundURL, useVoiceOnAC, setLoginClicked)} className="btn wumboprimary">Login</button>
				</div>
			</div>
		</div>
	);
	
}

export default CFNotificationsLogin;
