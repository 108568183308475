//@flow strict

import {Link} from 'react-router-dom';
import '../Main.css';

function Navbar() {
	return (
		<div>
			<ul className="directory">
				<li><Link className="directory" to="/Games">Games</Link></li>
				<li><Link className="directory" to="/BCPC">BCPC</Link></li>
				<li><Link className="directory" to="/CompetitiveProgramming">Competitive Programming</Link></li>
				<li><Link className="directory" to="/CompetitiveProgramming/LGMSniper">LGM Sniper</Link></li>
				<li><Link className="directory" to="/CFNotifications">CF Notifications</Link></li>
			</ul>
			<br className="clearAll"/>
		</div>
	);
}

export default Navbar;
