//@flow strict

import {ReactNode} from 'react';
import WumboBanner from '../global/WumboBanner';
import Navbar from '../global/Navbar';
import '../Main.css';
import CheckIcon from './checkmarkMonotone.png';
import XIcon from './XMonotone.png';
import LoadingGif from '../WumboLogoAnimation.gif';
import getLGMMatchupResults from './getLGMMatchupResults';
import postSiteVisit from '../global/postSiteVisit';

import type {AllUsersResult, UserQueryResult, RoundResult, UserRating} from './getLGMMatchupResults';
import { useState } from 'react';
import { useEffect } from 'react';

const LGM_RATING=3000;

function streq(str1: string, str2: string): boolean {
	return str1.toUpperCase() === str2.toUpperCase();
}

function onSearchClicked(
	setIsLoading: React.Dispatch<React.SetStateAction<boolean>>, 
	setAllUserResults: React.Dispatch<any>, 
	setAllRatings: React.Dispatch<React.SetStateAction<UserRating[]>>,
	handle1Text: string,
	handle2Text: string,
	handle3Text: string,
	handle4Text: string,
): void {
	const userHandles=[];
	if (handle1Text.length>0) {
		userHandles.push(handle1Text);
	}
	if (handle2Text.length>0) {
		userHandles.push(handle2Text);
	}
	if (handle3Text.length>0) {
		userHandles.push(handle3Text);
	}
	if (handle4Text.length>0) {
		userHandles.push(handle4Text);
	}
	console.log(userHandles);
	setIsLoading(true);
	getLGMMatchupResults(userHandles, (allUserResults, allRatings)=> {
		setAllUserResults(allUserResults);
		setAllRatings(allRatings);
		setIsLoading(false);
	});
}

function ratingOf(handle: string, allRatings: UserRating[]): number {
	const userRating=allRatings.find(r => streq(r.handle, handle));
	if (userRating == null) {
		throw new Error('Couldnt find rating of user in returned handles!');
	}
	return userRating.rating;
}

function stylizeName(handle: string, allRatings: UserRating[]): ReactNode {
	const rating=ratingOf(handle, allRatings);
	let color='#000000';
	if (streq(handle, "MikeMirzayanov")) {
		color='#000000';
	}
	else if (rating<1200 || streq(handle, "atfiona")) {
		color='#888888';
	}
	else if (rating<1400) {
		color='#339933';
	}
	else if (rating<1600) {
		color='#118888';
	}
	else if (rating<1900) {
		color='#3333cc';
	}
	else if (rating<2100) {
		color='#cc33cc';
	}
	else if (rating<2300) {
		color='#e6ac00';
	}
	else if (rating<2400) {
		color='#e68a00';
	}
	else if (rating<2600) {
		color='#ee1111';
	}
	else if (rating<3000) {
		color='#a90000';
	}
	else {
		color='#a90000';
		const first=handle.substring(0, 1);
		const second=handle.substring(1);
		return <div><span style={{color: '#000000'}}>{first}</span><span style={{color: color}}>{second}</span></div>
	}
	console.log(handle+' '+color);
	return <span style={{color: color}}>{handle}</span>
}

function getDataFor(
	user: string, 
	lgm: string, 
	allUserResults: AllUsersResult, 
	lgmRequired: boolean, 
	showWinMatchupRatio: boolean, 
	minWinPercentage: number
): ReactNode {

	let roundResults: Array<RoundResult> = allUserResults[user][lgm];
	let roundsUserWon = roundResults.filter(round => round.userWon);
	if (lgmRequired) {
		roundsUserWon = roundsUserWon.filter(round => round.precontestLGMRating >= LGM_RATING);
		roundResults = roundResults.filter(round => round.precontestLGMRating >= LGM_RATING);
	}

	const roundCountText = showWinMatchupRatio ?
		roundsUserWon.length+'/'+roundResults.length:
		'';

	let tooltipData=user+' beat '+lgm+' in:\n';
	for (const round of roundsUserWon) {
		tooltipData+=round.roundName+' /contest/'+round.roundId+'\n';
	}
	const percentWins=roundsUserWon.length/roundResults.length;

	if (roundResults.length===0 || streq(user, lgm)) {
		return (<td key={user+'__'+lgm}></td>);
	}
	else if (percentWins>=minWinPercentage) {
		return (
			<td className="checkmark" key={user+'__'+lgm}>
				<div className="mx-auto text-center">
					{
						// (warning about no href, we just want it for the tooltip)
						// eslint-disable-next-line
						(<a title={tooltipData}><img src={CheckIcon} alt="" width="30px"/></a>)
					}
					<br className="clearAll"/>
					{roundCountText}
				</div>
			</td>
		);
	}
	else {
		return (
			<td className="xmark" key={user+'__'+lgm}>
				<div className="mx-auto text-center">
					<img src={XIcon} alt="" width="30px"/>
					<br className="clearAll"/>
					{roundCountText}
				</div>
			</td>
		);
	}
}

function getTable(
	allUserResults: AllUsersResult, 
	allRatings: UserRating[], 
	showExLGMs: boolean, 
	lgmRequired: boolean, 
	showWinMatchupRatio: boolean, 
	minWinPercentage: number
): ReactNode {
	const allResults=allUserResults;
	if (allResults == null) {
		return (<div>hmm, no user results...</div>);
	}
	let lgms: Array<string>=[];
	for (const username of Object.keys(allResults)) {
		const userResult: UserQueryResult=allResults[username];
		for (const lgm of Object.keys(userResult)) {
			if (!lgms.includes(lgm)) {
				lgms.push(lgm);
			}
		}
	}
	const users: Array<string> = Object.keys(allResults);
	lgms.sort((a, b) => {
		return ratingOf(b, allRatings)-ratingOf(a, allRatings);
	});
	if (!showExLGMs) {
		lgms=lgms.filter(lgm => ratingOf(lgm, allRatings)>=LGM_RATING);
	}
	return (
		<div>
			<br className="clearAll"/>
			<table className="table col-md-10">
				<thead>
					<tr>
						<th scope="col" className="col-md-4"></th>
						{users.map(user=>
							<th scope="col" className="col-md-4" key={user}><div className="text-center">{
								stylizeName(user, allRatings)
							}</div></th>
						)}
					</tr>
				</thead>
				<tbody>
					{lgms.map(lgm=>
						<tr key={lgm}>
							<th scope="row"><div className="text-right">{
								stylizeName(lgm, allRatings)
							}</div></th>	
							{users.map(user=>getDataFor(user, lgm, allResults, lgmRequired, showWinMatchupRatio, minWinPercentage))}
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
}

function LGMSniper() {
	const [allUserResults, setAllUserResults] = useState<AllUsersResult|null>(null);
	const [showWinMatchupRatio, setShowWinMatchupRatio] = useState(false);
	const [showExLGMs, setShowExLGMs] = useState(false);
	const [allRatings, setAllRatings] = useState<Array<UserRating>>([]);
	const [minWinPercentage, setMinWinPercentage] = useState(0.001);
	const [isLoading, setIsLoading] = useState(false);
	const [lgmRequired, setLGMRequired] = useState(false);
	const [handle1Text, setHandle1Text] = useState("");
	const [handle2Text, setHandle2Text] = useState("");
	const [handle3Text, setHandle3Text] = useState("");
	const [handle4Text, setHandle4Text] = useState("");
	
	useEffect(() => {
		postSiteVisit('/CompetitiveProgramming/LGMSniper');
	}, []);

	let contents=null;
	if (isLoading) {
		contents=(
		<div className="mx-auto text-center loadingdiv">
			<img src={LoadingGif} alt="" className="col-md-4"/>
			<br className="clearAll"/>
			Analyzing results of over 400 contests...
		</div>);
	}
	else if (allUserResults!=null) {
		contents=(
			<div>
				<div className="form-group">
					Show Ex-LGMs <input type="checkbox" onChange={(e) => setShowExLGMs(e.currentTarget.checked)}/>
				</div>
				<div className="form-group">
					Show win matchup ratio <input type="checkbox" onChange={(e) => setShowWinMatchupRatio(e.currentTarget.checked)} />
				</div>
				<div className="form-group">
					Only consider rounds where opponent was an LGM at time of round <input type="checkbox" onChange={(e) => setLGMRequired(e.currentTarget.checked)} />
				</div>
				<div className="form-group">
					Win percent required to be sniped: {Math.round(minWinPercentage*1000)/10}%
					<br className="clearAll"/>
					<input 
						type="range" 
						min="0.001" 
						max="1" 
						step="0.001"	
						className="custom-range" 
						onChange={e => setMinWinPercentage(Number(e.currentTarget.value))} 
					/>
				</div>
				{getTable(allUserResults, allRatings, showExLGMs, lgmRequired, showWinMatchupRatio, minWinPercentage)}
			</div>
		);
	}
	else {
		contents=(
			<div>
				<p>
					Enter your CodeForces handle below to see which LGM's you have beaten. If you are on a team
					(or are naughty and have an alt account), you can enter multiple accounts and see the union
					of LGMs you have beaten.
				</p>
				<div className="col-md-3 mx-auto">
					<div className="form-group">
						Handle #1: <input type="text" className="fullWidth" value={handle1Text} onChange={e=>setHandle1Text(e.currentTarget.value)} />
					</div>
					<div className="form-group">
						Handle #2: <input type="text" className="fullWidth" value={handle2Text} onChange={e=>setHandle2Text(e.currentTarget.value)} />
					</div>
					<div className="form-group">
						Handle #3: <input type="text" className="fullWidth" value={handle3Text} onChange={e=>setHandle3Text(e.currentTarget.value)} />
					</div>
					<div className="form-group">
						Handle #4: <input type="text" className="fullWidth" value={handle4Text} onChange={e=>setHandle4Text(e.currentTarget.value)} />
					</div>
					<div className="text-center">
						<button className="btn wumboprimary" onClick={() => onSearchClicked(setIsLoading, setAllUserResults, setAllRatings, handle1Text, handle2Text, handle3Text, handle4Text)}>
							Search
						</button>
					</div>
				</div>
			</div>
		);
	}
	return (
		<div>
			<WumboBanner />
			<div className="maintext">
				<Navbar />
				<h1 className="title">
					LGM Sniper
				</h1>
				{contents}
				<br className="clearAll" />
			</div>
		</div>
	);
}

export default LGMSniper;
