
//@flow strict

import * as React from 'react';
import WumboBanner from '../../global/WumboBanner';
import Navbar from '../../global/Navbar';
import PictureAndDescription from '../../global/PictureAndDescription';
import ColarityTitlescreen from './colaritytitlescreen.png';
import ColarityScreenshot1 from './colarityScreenshot1.png';
import ColarityScreenshot2 from './colarityScreenshot2.png';
import ColarityScreenshot3 from './colarityScreenshot3.png';
import postSiteVisit from '../../global/postSiteVisit';
import '../../Main.css';

const GAME_DOWNLOAD = '/Games/Colarity/Play';

function Colarity(): JSX.Element {
	React.useEffect(() => postSiteVisit('/games/Colarity'), []);

	return (
		<div>
			<WumboBanner />
			<div className="maintext">
				<Navbar />
				<PictureAndDescription imageSrc={ColarityTitlescreen} imageDownload={GAME_DOWNLOAD}>
					Colarity is a puzzle-combat tank shooter created by Joshua Fair, David Harmeyer, and Mary McIntosh.
					Play it in your browser at WumboGames.com, or download it from the App store!
					If you have any questions, comments, or concerns, please email 13dharmeyer@gmail.com for support.
				</PictureAndDescription>
				<div className="indented">
					<a href={GAME_DOWNLOAD} title="Play Now">Play Now!</a>
					<br className="clearAll"/>
					<br className="clearAll"/>
					<h2 className="title"><u>Screenshots:</u></h2>	
					<img src={ColarityScreenshot3} width="80%" className="center" alt=""/>
					<div className="photolist"><img src={ColarityScreenshot1} width="400px" className="center" alt=""/></div>
					<div className="photolistright"><img src={ColarityScreenshot2} width="400px" className="center" alt=""/></div>
					<br className="clearAll"/>
				</div>
			</div>
		</div>
	);
}

export default Colarity;
