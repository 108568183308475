//@flow strict

import { useEffect } from 'react';
import type {Sounds} from './Sounds';
import ReactPlayer from 'react-player';
import ytSearchAPICall from './ytSearchAPICall';
import React from 'react';
import useInterval from 'react-useinterval';

type Props = {
	soundToPlay: Sounds | null,
	volume: number,
	shouldUpdateURLs: boolean,

	//used for CFNotifications to let users 
	//play custom videos
	customURL?: string|null,
};

const URL_FOR_SOUND = {
	WHITE_NOISE: 'https://youtu.be/wzjWIxXBs_s',
	RAIN_NOISE: 'https://youtu.be/ekXFslHOvZ8',
	SWING_MUSIC: 'https://youtu.be/Q0g96F0wEGA',
	WORKING_MUSIC: 'https://www.youtube.com/watch?v=3CS93CdMv_E&list=PLog01qP4mQ8v7MJ1RXjLCsJBKpJ0SeiJl',
	CHIPTUNES: 'https://youtu.be/W7aH4be6aEg',
	CHRISTMAS: 'https://youtu.be/5tXht-NGmc0',
	PIANO: 'https://youtu.be/RgC-8VbHMPg',
}

function updateURLs(): void {
	console.log('updating urls...');
	ytSearchAPICall('electro swing radio immortal swings', (videoId)=>{
		URL_FOR_SOUND.SWING_MUSIC='https://youtu.be/'+videoId
	});
	ytSearchAPICall('krelez chiptune radio', (videoId)=>{
		URL_FOR_SOUND.CHIPTUNES='https://youtu.be/'+videoId
	});
}

function VideoPlayer(props: Props) {

	useEffect(() => {
		if (props.shouldUpdateURLs) {
			updateURLs();
		}
	}, [props.shouldUpdateURLs]);

	useInterval(() => {
		if (props.shouldUpdateURLs) {
			updateURLs()
		}
	}, 1000*60*60*2);


	
	const customURL=props.customURL;
	if (customURL==null) {
		const toPlay=props.soundToPlay;
		if (toPlay==null || toPlay==='STOP') {
			return (<div></div>);
		}
		else {
			return (
				<ReactPlayer url={URL_FOR_SOUND[toPlay]} playing={true} volume={props.volume/100}/>
			);
		}
	}
	else {
		return (
			<ReactPlayer url={customURL} playing={true} volume={props.volume/100}/>
		);
	}
}

export default VideoPlayer;
