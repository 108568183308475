//@flow strict

import * as React from 'react';
import WumboBanner from '../global/WumboBanner';
import Dictaphone from './Dictaphone';
import VideoPlayer from './videos/VideoPlayer';
import evaluateTextForMeaning from './apiRequests/evaluateTextForMeaning';
import handleTextEvaluation from './handleTextEvaluation';
import tryAnsweringRequests from './tryAnsweringRequests';
import ZeroAnimation from './animation/ZeroAnimation';
import '../Main.css';
import {initCFChecker} from './codeforcesAPI/checkForNewSubmissions';
import checkForNewSubmissions from './codeforcesAPI/checkForNewSubmissions';

import type {Sounds} from './videos/Sounds';
import { useState } from 'react';
import { useEffect } from 'react';
import useInterval from 'react-useinterval';

type Props={
	password: string
};

const synth = window.speechSynthesis;

function getVoice(): SpeechSynthesisVoice {
	const allVoices=synth.getVoices();
	let selectedVoice = allVoices.find(v => v.name.startsWith('Microsoft Eva'));
	if (selectedVoice == null) {
		console.log('Using backup zira voice');
		selectedVoice = allVoices.find(v => v.name.startsWith('Microsoft Zira'));
	}
	if (selectedVoice == null) {
		console.log('Using backup Ava voice for mac');
		selectedVoice = allVoices.find(v => v.name.startsWith('Ava'));
	}
	if (selectedVoice == null) {
		console.log('Using backup Samantha voice for mac');
		selectedVoice = allVoices.find(v => v.name.startsWith('Samantha'));
	}
	if (selectedVoice == null) {
		console.log('Voice names: ');
		console.log(window.speechSynthesis.getVoices().map(v => v.name));
		console.log('Couldnt find correct voice!');
		selectedVoice = allVoices[0];
	}
	return selectedVoice;
}

function sayString(
	toSay: string, 
	afterSpeech: (() => void) | null, 
	rate: number | null, 
	volume: number, 
	setLastClearTime: React.Dispatch<React.SetStateAction<Date>>
): void {
	const utterance = new SpeechSynthesisUtterance(toSay);
	utterance.voice=getVoice();
	utterance.rate=rate ?? 1.2;
	console.log(utterance.rate);
	utterance.volume=volume/100;
	synth.speak(utterance);
	const loop= () => {
		if (synth.speaking) {
			setTimeout(loop, 100);
			setLastClearTime(new Date())
		}
		else {
			if (afterSpeech != null) {
				afterSpeech();
			}
		}
	}
	setTimeout(loop, 100)
}

function evaluateText(
	text: string, 
	props: Props, 
	listening: boolean, 
	volume: number, 
	setLastClearTime: React.Dispatch<React.SetStateAction<Date>>,
): void {
	evaluateTextForMeaning(text, 
		evaluation => handleTextEvaluation(
			evaluation, 
			props.password,
			listening,
			(toSay: string) => 
				sayString(toSay, null, null, volume, setLastClearTime),
			volume,
		)
	);
}

function answerLoop(
	props: Props,
	volume: number, 
	setLastClearTime: React.Dispatch<React.SetStateAction<Date>>,
	setSoundPlaying: React.Dispatch<React.SetStateAction<Sounds | null>>,
	setListening: React.Dispatch<React.SetStateAction<boolean>>,
	setScreenDim: React.Dispatch<React.SetStateAction<boolean>>,
	setVolume: React.Dispatch<React.SetStateAction<number>>,
): void {
	tryAnsweringRequests(
		props.password, 
		(string, after, rate) => sayString(string, after, rate, volume, setLastClearTime),
		(musicType: Sounds) => setSoundPlaying(musicType),
		(listening) => setListening(listening),
		(screenDim) => setScreenDim(screenDim),
		(volume) => setVolume(volume),
	);	
}

function cfLoop(props: Props): void {
	checkForNewSubmissions(props.password);
}

function ZeroHome(props: Props) {
	const [soundPlaying, setSoundPlaying] = useState<Sounds|null>(null);
	const [slow, setSlow] = useState(false);
	const [lastClearTime, setLastClearTime] = useState(new Date());
	const [listening, setListening] = useState(true);
	const [screenDim, setScreenDim] = useState(false);
	const [volume, setVolume] = useState(50);
	const [testInputText, setTestInputText] = useState("");

	useEffect(() => {
		initCFChecker('SecondThread');
	}, []);

	useInterval(() => answerLoop(props, volume, setLastClearTime, setSoundPlaying, setListening, setScreenDim, setVolume), 100);
	useInterval(() => cfLoop(props), 1000);

	

	const setSmall = (slow: boolean) => setSlow(slow);

	const debug=true;
	return (
		<div>
			{screenDim?<div></div>:<WumboBanner />}
			<div className="maintext black">
			<br className="clearAll"/>
				{screenDim?<div></div>:
					<ZeroAnimation 
						slow={slow} 
						listening={listening}
					/>
				}
				<Dictaphone 
					processText={(text) => evaluateText(text, props, listening, volume, setLastClearTime)}
					setSmall={setSmall}
					lastClearTime={lastClearTime}
					listening={listening}
					screenDim={screenDim}
				/>
				<br className="clearAll"/>
				<div className='largeBlock'> </div>
				{
					debug?
					<div>
						<input className="form-control" value={testInputText} onChange={e => setTestInputText(e.currentTarget.value)}/>
						<button onClick={() => evaluateText(testInputText, props, listening, volume, setLastClearTime)}> 
							Test 
						</button>
					</div>
					:
					<div></div>
				}
				<VideoPlayer 
					soundToPlay={soundPlaying} 
					volume={volume}
					shouldUpdateURLs={true}
					customURL={null}
				/>
			</div>
		</div>
	);
}

export default ZeroHome;
