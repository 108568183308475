//@flow strict

function playCompletedSound(): void {
    const audio=new Audio('/Completed.wav');
    audio.play();
    console.log(
        'Playing audio!'
    )
}

export default playCompletedSound;