//@flow strict

import * as React from 'react';
import WumboBanner from '../global/WumboBanner';
import Navbar from '../global/Navbar';
import postSiteVisit from '../global/postSiteVisit';
import '../Main.css';

type ProblemDifficulty = 'D2A' | 'D2B' | 'D1A' | 'D1B' | 'D1C' | 'D1D' | 'D1E';

type ProblemDefinition = {
	name: string,
	difficulty: ProblemDifficulty,
	downloadLink: string,	
	contest: string,
	year: string,
	dataLink?: string,
}

const problemList: Array<ProblemDefinition> = [
	{
		name: 'String Manipulation',
		difficulty: 'D2A',
		downloadLink: 'APComputerScienceContest.pdf',
		contest: "Osterberg's APCS Practice Contest",
		year: '2016'
	},
	{
		name: 'When One Door Closes',
		difficulty: 'D2A',
		downloadLink: 'APComputerScienceContest.pdf',
		contest: "Osterberg's APCS Practice Contest",
		year: '2016'
	},
	{
		name: 'Lonely Integer',
		difficulty: 'D2A',
		downloadLink: 'APComputerScienceContest.pdf',
		contest: "Osterberg's APCS Practice Contest",
		year: '2016'
	},
	{
		name: 'Recreational Excursions',
		difficulty: 'D2B',
		downloadLink: 'APComputerScienceContest.pdf',
		contest: "Osterberg's APCS Practice Contest",
		year: '2016'
	},
	{
		name: 'Toaster Theft',
		difficulty: 'D2B',
		downloadLink: 'APComputerScienceContest.pdf',
		contest: "Osterberg's APCS Practice Contest",
		year: '2016'
	},
	{
		name: 'Walking Around Trees',
		difficulty: 'D2A',
		downloadLink: 'APComputerScienceContest.pdf',
		contest: "Osterberg's APCS Practice Contest",
		year: '2016'
	},
	{
		name: 'Passing Bills',
		difficulty: 'D2A',
		downloadLink: 'BCPC Problems.pdf',
		contest: "BCPC1",
		year: '2017'
	},
	{
		name: 'Apple Pen',
		difficulty: 'D2A',
		downloadLink: 'BCPC Problems.pdf',
		contest: "BCPC1",
		year: '2017'
	},
	{
		name: 'The Halting Problem',
		difficulty: 'D2B',
		downloadLink: 'BCPC Problems.pdf',
		contest: "BCPC1",
		year: '2017'
	},
	{
		name: 'One',
		difficulty: 'D2B',
		downloadLink: 'BCPC Problems.pdf',
		contest: "BCPC1",
		year: '2017'
	},
	{
		name: 'Negligent Norbert',
		difficulty: 'D1A',
		downloadLink: 'BCPC Problems.pdf',
		contest: "BCPC1",
		year: '2017'
	},
	{
		name: 'Calculatus Eliminatus',
		difficulty: 'D2B',
		downloadLink: 'BCPC Problems.pdf',
		contest: "BCPC1",
		year: '2017'
	},
	{
		name: 'Bike Race',
		difficulty: 'D2B',
		downloadLink: 'BCPC Problems.pdf',
		contest: "BCPC1",
		year: '2017'
	},
	{
		name: 'LAST Robotics',
		difficulty: 'D1A',
		downloadLink: 'BCPC1Bonus.pdf',
		contest: "BCPC1",
		year: '2017'
	},
	{
		name: 'Everybody Do the Flop',
		difficulty: 'D2A',
		downloadLink: 'BCPC2 Problems.pdf',
		contest: "BCPC2",
		year: '2018'
	},
	{
		name: 'Funny Pairs',
		difficulty: 'D2A',
		downloadLink: 'BCPC2 Problems.pdf',
		contest: "BCPC2",
		year: '2018'
	},
	{
		name: 'Several-Part Staircase',
		difficulty: 'D2B',
		downloadLink: 'Several-Part Staircase.pdf',
		contest: "the Florida High School Programming Series",
		year: '2018'
	},
	{
		name: 'Octo-Octothorpes',
		difficulty: 'D1A',
		downloadLink: 'Octo-Octothorpes.pdf',
		contest: "the Florida High School Programming Series",
		year: '2018'
	},
	{
		name: 'Flipper-McFlopper',
		difficulty: 'D2B',
		downloadLink: 'Flipper-McFlopper.pdf',
		contest: "the Florida High School Programming Series",
		year: '2018'
	},
	{
		name: 'Goat Simulator',
		difficulty: 'D2B',
		downloadLink: 'BCPC2 Problems.pdf',
		contest: "BCPC2",
		year: '2018'
	},
	{
		name: 'Codeforces Handle',
		difficulty: 'D1A',
		downloadLink: 'BCPC2 Problems.pdf',
		contest: "BCPC2",
		year: '2018'
	},
	{
		name: 'Tax Evasion',
		difficulty: 'D1A',
		downloadLink: 'BCPC2 Problems.pdf',
		contest: "BCPC2",
		year: '2018'
	},
	{
		name: 'Two',
		difficulty: 'D1A',
		downloadLink: 'BCPC2 Problems.pdf',
		contest: "BCPC2",
		year: '2018'
	},
	{
		name: 'Tic Tacs',
		difficulty: 'D1B',
		downloadLink: 'Bonus Problem_ Tic Tacs.pdf',
		contest: "BCPC2",
		year: '2018'
	},
	{
		name: 'Hyper Even Numbers',
		difficulty: 'D2B',
		downloadLink: 'problemSet1819Online.pdf',
		contest: "UCF Online HSPT 2018-19",
		year: '2018'
	},
	{
		name: 'Flooding the Tri-State Area',
		difficulty: 'D1A',
		downloadLink: 'problemSet1819Online.pdf',
		contest: "UCF Online HSPT 2018-19",
		year: '2018'
	},
	{
		name: 'Blurry Sunshiny Day',
		difficulty: 'D2B',
		downloadLink: 'problemSet2019.pdf',
		contest: "UCF HSPT 2019",
		year: '2019'
	},
	{
		name: 'Huge Super Perfect Track',
		difficulty: 'D1B',
		downloadLink: 'problemSet2019.pdf',
		contest: "UCF HSPT 2019",
		year: '2019'
	},
	{
		name: 'A Man, A Plan, A Canal, Panama',
		difficulty: 'D1D',
		downloadLink: 'problemSet1920Online-Div1.pdf',
		contest: "UCF Online HSPT 2019-20, Div1",
		year: '2019'
	},
	{
		name: 'Hanukkah Graphs',
		difficulty: 'D1A',
		downloadLink: 'problemSet1920Online-Div1.pdf',
		contest: "UCF Online HSPT 2019-20, Div1 + Div2",
		year: '2019'
	},
	{
		name: 'Super Scabble',
		difficulty: 'D1B',
		downloadLink: 'problemSet1920Online-Div1.pdf',
		contest: "UCF Online HSPT 2019-20, Div1 + Div2",
		year: '2019'
	},
	{
		name: 'Election Fraud',
		difficulty: 'D1B',
		downloadLink: 'problemSet1920Online-Div2.pdf',
		contest: "UCF Online HSPT 2019-20, Div2",
		year: '2019'
	},
	{
		name: '99 Red Balloons',
		difficulty: 'D2A',
		downloadLink: 'BCPC3 Problems.pdf',
		contest: "BCPC3",
		year: '2019'
	},
	{
		name: 'Frenemies',
		difficulty: 'D1B',
		downloadLink: 'BCPC3 Problems.pdf',
		contest: "BCPC3",
		year: '2019'
	},
	{
		name: 'Frosty the Milkshake',
		difficulty: 'D2B',
		downloadLink: 'BCPC3 Problems.pdf',
		contest: "BCPC3",
		year: '2019'
	},
	{
		name: 'Linus and Snoopy',
		difficulty: 'D1A',
		downloadLink: 'BCPC3 Problems.pdf',
		contest: "BCPC3",
		year: '2019'
	},
	{
		name: 'Planes',
		difficulty: 'D1A',
		downloadLink: 'BCPC3 Problems.pdf',
		contest: "BCPC3",
		year: '2019'
	},
	{
		name: 'Special Snowflakes',
		difficulty: 'D2B',
		downloadLink: 'BCPC3 Problems.pdf',
		contest: "BCPC3",
		year: '2019'
	},
	{
		name: 'Three',
		difficulty: 'D1A',
		downloadLink: 'BCPC3 Problems.pdf',
		contest: "BCPC3",
		year: '2019'
	},
	{
		name: 'Windows 10',
		difficulty: 'D1A',
		downloadLink: 'BCPC3 Problems.pdf',
		contest: "BCPC3",
		year: '2019'
	},
	{
		name: 'Oppisite Day',
		difficulty: 'D1C',
		downloadLink: 'BCPC3 Bonus Problem_ Oppisite Day.pdf',
		contest: "BCPC3",
		year: '2019'
	},
	{
		name: 'In a Jam',
		difficulty: 'D1B',
		downloadLink: 'problemSet2020.pdf',
		contest: "UCF HSPT 2020",
		year: '2020'
	},
	{
		name: 'Sock Sort',
		difficulty: 'D1A',
		downloadLink: 'problemSet2020.pdf',
		contest: "UCF HSPT 2020",
		year: '2020'
	},
	{
		name: 'Building Bubblers',
		difficulty: 'D1A',
		downloadLink: 'arupsClass/Building Bubblers.pdf',
		contest: 'University of Central Florida Team Dynamics Final Exam',
		dataLink: 'arupsClass/bubblers.zip',
		year: '2020'
	},
	{
		name: 'Elf Employment',
		difficulty: 'D1C',
		downloadLink: 'arupsClass/Elf Employment.pdf',
		contest: 'University of Central Florida Dynamics Final Exam',
		dataLink: 'arupsClass/elf.zip',
		year: '2020'
	},
	{
		name: 'Nap Sack',
		difficulty: 'D1A',
		downloadLink: 'si/Nap Sack.pdf',
		contest: "University of Central Florida SI Camp, Contest 1",
		dataLink: 'si/napsack.zip',
		year: '2020'
	},
	{
		name: 'Teja Vu',
		difficulty: 'D1A',
		downloadLink: 'si/Teja Vu.pdf',
		contest: "University of Central Florida SI Camp, Contest 1",
		dataLink: 'si/vu.zip',
		year: '2020'
	},
	{
		name: 'New Years Lights',
		difficulty: 'D1C',
		downloadLink: 'si/New Years Lights.pdf',
		contest: "University of Central Florida SI Camp, Contest 1",
		dataLink: 'si/newyears.zip',
		year: '2020'
	},
	{
		name: 'Div2B or not Div2B',
		difficulty: 'D1D',
		downloadLink: 'si/TwoB.pdf',
		contest: "University of Central Florida SI Camp, Contest 1",
		dataLink: 'si/twob.zip',
		year: '2020'
	},
	{
		name: 'Christmas Lights',
		difficulty: 'D2A',
		downloadLink: 'si/Christmas Lights.pdf',
		contest: "University of Central Florida SI Camp, Contest 2",
		dataLink: 'si/christmas.zip',
		year: '2020'
	},
	{
		name: 'Snake and Ladders',
		difficulty: 'D1A',
		downloadLink: 'si/Snake and Ladders.pdf',
		contest: "University of Central Florida SI Camp, Contest 2",
		dataLink: 'si/snake.zip',
		year: '2020'
	},
	{
		name: 'Marginal Utility',
		difficulty: 'D2A',
		downloadLink: 'si/Marginal Utility.pdf',
		contest: "University of Central Florida SI Camp, Contest 3",
		dataLink: 'si/marginal.zip',
		year: '2020'
	},
	{
		name: 'Teja Queue',
		difficulty: 'D1A',
		downloadLink: 'si/Teja Queue.pdf',
		contest: "University of Central Florida SI Camp, Contest 3",
		dataLink: 'si/queue.zip',
		year: '2020'
	},
	{
		name: "Santa's Snooze",
		difficulty: 'D1A',
		downloadLink: "si/Santa's Snooze.pdf",
		contest: "University of Central Florida SI Camp, Contest 3",
		dataLink: 'si/snooze.zip',
		year: '2020'
	},
	{
		name: 'Hash Collisions',
		difficulty: 'D1B',
		downloadLink: 'si/Hash Collisions.pdf',
		contest: "University of Central Florida SI Camp, Contest 4",
		dataLink: 'si/hash.zip',
		year: '2020'
	},
	{
		name: 'Min niM',
		difficulty: 'D1A',
		downloadLink: 'si/Min niM.pdf',
		contest: "University of Central Florida SI Camp, Contest 4",
		dataLink: 'si/minnim.zip',
		year: '2020'
	},
	{
		name: 'Teja New',
		difficulty: 'D1B',
		downloadLink: 'si/Teja New.pdf',
		contest: "University of Central Florida SI Camp, Contest 4",
		dataLink: 'si/tnew.zip',
		year: '2020'
	},
	{
		name: 'Cutting Corners',
		difficulty: 'D2A',
		downloadLink: 'si/Cutting Corners.pdf',
		contest: "University of Central Florida SI Camp, Contest 5",
		dataLink: 'si/corners.zip',
		year: '2020'
	},
	{
		name: 'Multiplication Verification Implementation',
		difficulty: 'D1C',
		downloadLink: 'si/Multiplication Verification Implementation.pdf',
		contest: "University of Central Florida SI Camp, Contest 5",
		dataLink: 'si/mvi.zip',
		year: '2020'
	},
	{
		name: 'Weather',
		difficulty: 'D2B',
		downloadLink: 'si/Under the Weather.pdf',
		contest: "University of Central Florida SI Camp, Contest 5",
		dataLink: 'si/weather.zip',
		year: '2020'
	},
]

function ProblemsIveWritten() {

	React.useEffect(() => {
		postSiteVisit('/CompetitiveProgramming/ProblemsIveWritten');
	}, []);

	return (
		<div>
			<WumboBanner />
			<div className="maintext">
				<Navbar />
				<h1 className="title">
					All Published Problems I've Written
				</h1>
					<ul className="normalList">
						{
							problemList.map(problem => (
								<li key={problem.name}> 
									{'(~'+problem.difficulty+') '}
									<a href={'/CompetitiveProgramming/'+problem.downloadLink}>
										{problem.name}
									</a>
									{' from '+problem.contest+' | '+problem.year}
									{
										(problem.dataLink==null?
											null
											:
											<span>{' | '}<a href={'/CompetitiveProgramming/'+problem.dataLink}>Data</a> </span>
										)
									}
								</li>
							))
						}
					</ul>
				<br className="clearAll"/>
				<h2 className="centeredText">My Favorites</h2>
				<p>
					Really cool solutions: Planes, Tic Tacs
				</p>
				<p>
					Particularly well writen: Funny Pairs, Octo-octothorpes, Election Fraud, Flipper McFlopper
				</p>
				<p>
					Solid easy problems: Sock sort, Hanukkah graphs, Special Snowflakes
				</p>
			</div>
		</div>
	);
}

export default ProblemsIveWritten;
