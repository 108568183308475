import './IawTest.css';

function IawTest() {
    return (
        <div className="whiteBackground">
            <h1>Page to test iaw</h1> 
            <br/>
            <input placeholder="Input to open keyboard"></input>

            <form name="sampleformForAutofill" onSubmit={()=>{console.log('Submitting form!'); return false;}}>
                <input name="firstname" placeholder="First name"/>
                <br/>
                <input name="lastname" placeholder="Last name"/>
                <br/>
                <input name="email" placeholder="Email"/>
                <br/>
                <input type="submit" value="Submit" id="submit"/>

            </form>

            <div className="bottom">
                v Bottom of screen: v
            </div>
        </div>
    );
}

export default IawTest;