//@flow strict

function deleteJobRequests(password: string, jobRequestId: string, onLoad: () => void): void {
	fetch('/api/zero/deleteJobRequest', {
		method: 'POST',
		headers: {
			'Access-Control-Allow-Origin': '*',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			password: password,
			id: jobRequestId,
		}),
	}).then(res => {
		if (res.status!==200) {
			console.log(res.status);
			console.log(res);
		}
		else {
			onLoad();
		}
	});
}

export default deleteJobRequests;
