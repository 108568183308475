//@flow strict

import JobRequest from '../jobs/JobRequest';

function createJobRequest(toCreate: JobRequest, password: string): void {
	const jobInfoJSON = JSON.stringify(toCreate.jobInfo);
	fetch('/api/zero/addJobRequest', {
		method: 'POST',
		headers: {
			'Access-Control-Allow-Origin': '*',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			password: password,
			jobType: toCreate.jobType,
			activationTime: toCreate.activationTime,
			abandonTime: toCreate.abandonTime,
			jobInfoJSON: jobInfoJSON,
		}),
	}).then(res => {
		console.log('Created job request, result: ');
		console.log(res);
	});
}

export default createJobRequest;
