//@flow strict

import * as React from 'react';
import WumboBanner from '../../global/WumboBanner';
import Navbar from '../../global/Navbar';
import PictureAndDescription from '../../global/PictureAndDescription';
import CrampedTitleScreen from './crampedtitlescreen.png';
import CrampedScreenshot1 from './crampedscreenshot1.png';
import CrampedScreenshot2 from './crampedscreenshot2.png';
import CrampedScreenshot3 from './crampedscreenshot3.png';
import CrampedScreenshot4 from './crampedscreenshot4.png';
import CrampedScreenshot5 from './crampedscreenshot5.png';
import postSiteVisit from '../../global/postSiteVisit';
import '../../Main.css';

const GAME_DOWNLOAD = '/games/cramped/cramped.jar';

function Cramped(): JSX.Element {
	React.useEffect(() => postSiteVisit('/games/Cramped'), []);
	
	return (
		<div>
			<WumboBanner />
			<div className="maintext">
				<Navbar />
				<PictureAndDescription imageSrc={CrampedTitleScreen} imageDownload={GAME_DOWNLOAD}>
					<p className="normal">Cramped is a puzzel platformer in which you must get back to your igloo.
					The only catch is that <b>you</b> make the level, and, following the
					<a href="http://ludumdare.com/compo/ludum-dare-31/?action=preview&uid=37149">Ludum
					Dare theme</a>, you have to fit the entire level in one screen.</p>
					<p className="normal">The controls for the game are introduced as you play-just be sure to 
					read the directions.
					Want to play? You can download and run the jar file below
					(you need to have Java installed).</p>
				</PictureAndDescription>
				<div className="indented">
					<a href={GAME_DOWNLOAD} title="Download">Download Here!</a>
					<br className="clearAll"/>
					<br className="clearAll"/>
					<h2 className="title"><u>Screenshots:</u></h2>	
					<img src={CrampedScreenshot1} width="80%" className="center" alt="" />
					<div className="photolist"><img src={CrampedScreenshot2} width="400px" className="center" alt="" /></div>
					<div className="photolistright"><img src={CrampedScreenshot3} width="400px" className="center" alt="" /></div>
					<br className="clearAll"/>
					<div className="photolist"><img src={CrampedScreenshot4} width="400px" className="center" alt="" /></div>
					<div className="photolistright"><img src={CrampedScreenshot5} width="400px" className="center" alt="" /></div>
					<br className="clearAll"/>
				</div>
			</div>
		</div>
	);
}

export default Cramped;
