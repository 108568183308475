//@flow strict

import type {JobInfo} from './JobInfo';
import type {Intents} from '../Intents';

class JobRequest {
	jobType: Intents;
	activationTime: Date;
	abandonTime: Date;
	jobInfo: JobInfo;

	_id: string;

	constructor(jobType: Intents, activationTime: Date, abandonTime: Date, jobInfo: JobInfo, _id: string = "") {
		this.jobType=jobType;
		this.activationTime=activationTime;
		this.abandonTime=abandonTime;
		this.jobInfo=jobInfo;
		this._id = _id;
	}

}

export default JobRequest;
