//@flow strict

import * as React from 'react';
import WumboBanner from '../global/WumboBanner';
import Navbar from '../global/Navbar';
import postSiteVisit from '../global/postSiteVisit';
import '../Main.css';

function CompetitiveProgramming(): JSX.Element {
	React.useEffect(() => postSiteVisit('/CompetitiveProgramming'), []);
	return (
		<div>
			<WumboBanner />
			<div className="maintext">
				<Navbar />
				<h1 className="title">
					Competitive Programming
				</h1>
				<p>
					Welcome to Wumbo Games' Competitive Programming section. This is where I put things other
					people might be interested in having to do with moderately serious competative programming.
					If you have a CodeForces account, you might want to check out my
						LGM Sniper tool
					to see how you stack up against your favorite LGMs on CodeForces. 
					You might also be interested in CF Noticiations, a setup-free website 
						that notifies you of your verdicts on codeforces.

					If you think I might be
					an author for a contest you will be competing in soon, you can also
						check out all published problems that I have written
					to get a feel for the kind of problems I tend to write. 
					If you are interested in this stuff,
					you might want to check out the
						Brookfield Computer Programming Challenge,
					a high school programming contest in Wisconsin that I run.
				</p>
				
				<h3>
					<ul className="normalList">
						<li><a className="titleLink" href="/CompetitiveProgramming/PointLineDuality">Point Line Duality Demo</a></li>
						<li><a className="titleLink" href="/CFNotifications">CFNotifications</a></li>
						<li><a className="titleLink" href="/CompetitiveProgramming/LGMSniper">LGM Sniper</a></li>
						<li><a className="titleLink" href="/CompetitiveProgramming/ProblemsIveWritten">Problems I Have Written</a></li>
						<li><a className="titleLink" href="/BCPC">BCPC Homepage</a></li>
					</ul>
				</h3>
			</div>
		</div>
	);
}

export default CompetitiveProgramming;
