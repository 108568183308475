import React from 'react';
import SpeechRecognition, {useSpeechRecognition} from 'react-speech-recognition';

/*
TODO: need to fix dictaphone so that it actually works correctly
*/

interface Props {
	processText: (text: string) => void,
	setSmall: (small: boolean) => void,
	lastClearTime: Date,
	listening: boolean,
	screenDim: boolean,
};

function considerProcessingText(transcript: string, listening: boolean, processText: (text: string) => void) {
	console.log('Trying to process the event: '+transcript)
	let containsZero=false;
	for (const str of transcript.split(' ')) {
		if (str==='0' || str === 'zero' || str === 'Zero') {
			containsZero=true;
		}
	}
	
	if (listening || containsZero) {
		if (transcript.split(' ').length>1) {
			processText(transcript);
		}
	}
}

/*
Dictaphone logic:
 - keep checking every 0.1 seconds to watch for changes
 - if after 1 second the transcript is still the same, then process it and clear it
 - otherwise if the transcript changes within 1 second, then ignore this query
*/

function Dictaphone(props: Props) {
	const {transcript, resetTranscript} = useSpeechRecognition();

	React.useEffect(() => {
		SpeechRecognition.startListening({continuous: true});
		return () => SpeechRecognition.stopListening();
	}, []);

	const {setSmall} = props;

	React.useEffect(() => {
		setSmall(transcript === '')
	}, [transcript, setSmall]);

	React.useEffect(() => {
		resetTranscript();
	}, [props.lastClearTime, resetTranscript]);

	React.useEffect(() => {
		let speechProcessed=false;
		const id = setTimeout(() => {
			speechProcessed=true;
			considerProcessingText(transcript, props.listening, props.processText);
			resetTranscript();
		}, 1000);

		return () => {
			if (!speechProcessed) {
				clearTimeout(id);
			}
		};
	}, [transcript, props.listening, props.processText, resetTranscript]);

	return (
		<div className="centeredText zeroText">
			{props.screenDim?'':'Transcript: '+transcript}
		</div>
	);
}

export default Dictaphone;
