//@flow strict
import getCurrentLocation from './getCurrentLocation';

type WeatherDescription = {
	description: string,
	main: string,
}

type TempObject = {
	min: number,
	max: number,
	eve: number,
	morn: number,
	day: number,
}

type WeatherObject = {
	clouds: number,
	humidity: number,
	temp: TempObject,
	weather: Array<WeatherDescription>,
	wind_speed: number,
	//some other stuff too probably
}

type FullResults = {
	current: WeatherObject,
	daily: Array<WeatherObject>,
	hourly: Array<WeatherObject>,
};

function kelvinToF(kelvin: number): number {
	return 9.0/5*(kelvin-273) + 32;
}

function makePrettyString(fullResults: FullResults, daysFromNow: number): string {
	if (daysFromNow>7) {
		return "Appologies sir, I can't read that far into the future";
	}
	if (daysFromNow<0) {
		return "Can't have negative days from now.";
	}
	const weatherObject = fullResults.daily[daysFromNow];
	if (weatherObject == null) {
		console.log(fullResults);
		console.log(daysFromNow);
		return "Appologies sir, I wasn't able to get the weather data as you requested...";
	}
	const description = weatherObject.weather[0].description;
	console.log('Weather on day: ');
	console.log(weatherObject);

	let dayPrefix = '';
	if (daysFromNow === 0) {
		dayPrefix = 'Today ';
	}
	else if (daysFromNow === 1) {
		dayPrefix = 'Tomorrow ';
	}
	else {
		dayPrefix = daysFromNow + ' days from now ';
	}
	const highTemp = Math.round(kelvinToF(weatherObject.temp.max));
	const humidity = weatherObject.humidity;
	const fullString = dayPrefix + 'expect '+description+' with '+
		humidity+ ' percent humidity and a high of '+highTemp+' degrees.';
	return fullString;
}

//gets the weather description
function getWeatherDescription(daysFromNow: number, onLoad: (stringToSay: string) => void): void {
	getCurrentLocation(coords => {
		console.log('Getting weather in location: '+coords.latitude+' '+coords.longitude);
		fetch('https://api.openweathermap.org/data/2.5/onecall'+
			'?lat='+coords.latitude+
			'&lon='+coords.longitude+
			'&appid=cf957f9b1c478fbd7f452ae16b5d3842'
		).then(res => {
			if (res.status !== 200) {
				console.log('Error getting weather!');
				console.log(res.status);
				console.log(res);
			}
			else {
				res.json().then((data: FullResults) => {
					//TODO make pretty string with approriate data
					const string = makePrettyString(data, daysFromNow);
					onLoad(string);
				});
			}
		});
	});
}

export default getWeatherDescription;
