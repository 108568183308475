//@flow strict

import {useEffect} from 'react';
import {useLocation} from "react-router-dom";
import createPing from '../apiRequests/createPing';

function CompleteJobRequestCreator() {
    const search = useLocation().search;
    const password = new URLSearchParams(search).get('password');

    useEffect(() => {
        if (password != null) {
            createPing(password);
        }
    }, [password]);
    return <div></div>
}

export default CompleteJobRequestCreator;