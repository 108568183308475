//@flow strict

import * as React from 'react';
import WumboBanner from '../../global/WumboBanner';
import Navbar from '../../global/Navbar';
import Scoreboard from './Scoreboard.png';
import PictureAndDescription from '../../global/PictureAndDescription';
import postSiteVisit from '../../global/postSiteVisit';
import '../../Main.css';

function BCPC3() {

	React.useEffect(() => postSiteVisit('/BCPC/BCPC3'), []);
	return (
		<div>
			<WumboBanner />
			<div className="maintext">
				<Navbar />
				<h1 className="title">3rd Annual Brookfield Computer Programming Challenge</h1>
				<p>
					Congrats to all the teams who competed this year! Problem sets, an analysis of the 
					problems, problem solution code, solution videos, and final scores are all available here.
				</p>
				<ul className="normalList">
					<li> <a href="/BCPC/BCPC3/BCPC3Problems.pdf" title="Download">2019 Problem Set download</a></li>
					<li> <a href="/BCPC/BCPC3/BCPC3Editorial.pdf" title="Download">2019 Problem Analysis</a></li>
					<li> <a href="/BCPC/BCPC3/BCPC3Data.zip" title="Download">2019 Data</a></li>
					<li> <a href="/BCPC/BCPC3/Solutions.zip" title="Download">2019 Solutions</a></li>
				</ul>

				<br className="clearAll"/>

				<h2 className="title centeredText" >Final Standings</h2>
				<PictureAndDescription imageSrc={Scoreboard}>
					<p className="normal">
						Congradulations to Comrades (Colten Webb, Amogh Bhatnagar, Shailesh Desai, and Bill Rui) for 
						winning, redpwn (Hye Sung Jung and Jacob Farrell) for getting second, and Dream Team Lite (Thomas
						Harmeyer, Nabil Hussani, Monish Kahlia, and Kevin Song) for getting third! Also, congrats to all 
						teams on their strong performance on this challenging set! We would also like to give an honorable
						mention to European Gang (Stepan Lukashev and Leonarda Pribanic) for solving 4 problems despite
						encouring issues in different versions of compilers.
					</p>
					<p className="normal">
						In the scoreboard to the left, H corresponds to problem Three, and G to problem Window; the rest
						are in the same order as the problem set.
					</p>
					<p className="normal">
						For those who were wondering, penalty points are scored in the typical ACM-ICPC style. Teams get 1
						penalty point per minute it takes them to solve a problem they end up solving, plus 20 penalty points
						per incorrect submission on a problem they end up solving. Problems a team does not solve do not
						contribute to their score at all. The winner is the team that solves the most problems. If there is
						a tie here, the winner is the team with fewest penalty points. The scoreboard here reflects the final
						score, with the exception that it shows a total of 7 wrong submissions by European Gang that were due
						to compiler differences and were not included in the final score. This did not change the final ranking
						of teams.
					</p>
				</PictureAndDescription>

				<br className="clearAll"/>

				<h2 className="title centeredText">Solution Videos</h2>
				<p className="normal">
					<a href= "https://www.youtube.com/playlist?list=PLZU0kmvryb_FqcPMRQLelbJKeRJuNDXWx">
						The solution videos are available on this playlist.
					</a> 
					I recorded them twice because the audio quality on the first run wasn't great, so there
					is one version that is all seperate videos, and another version that is everything in
					one video. If one version isn't very helpful, the other might be worth checking out. If
					you have any comments, questions, concerns, or recommendations for next year, please email
					me at 13dharmeyer@gmail.com.
				</p>
			</div>
		</div>
	);
}

export default BCPC3;
