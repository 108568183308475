//@flow strict

import * as React from 'react';
import Games from './games/Games';
import './Main.css';

function App() {
	return (
		<Games />
	);
}

export default App;
