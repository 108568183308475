//@flow strict

import * as React from 'react';
import WumboBanner from '../global/WumboBanner';
import postSiteVisit from '../global/postSiteVisit';
import '../Main.css';

function PrivacyPolicy() {
	React.useEffect(() => {
		postSiteVisit('/PrivacyPolicy');
	}, []);

	return (
		<div>
			<WumboBanner />
			<div className="maintext">
				<h1 className="title">Privacy Policy</h1>
				<p>
					WumboGames collects the following data on websites requests:
					the site requested,
					the public ip address of the client who requests the page,
					and the time that the page is requested.
				</p>

				<p>
					This information is stored and used to decide which packets to
					drop in the event that the site recieves too much traffic. The
					IP address is stored to help combat DOS attacks.
				</p>
				<p>
					The Colarity app on the ios app store does not store any
					information about users collected by Wumbo Games. All information
					that the app collects or stores about you other than what is stored
					locally on your iphone is collected by Apple. Please refer to 
					Apple's privacy policy for any privacy concerns there.
				</p>
				<p>
					The Colarity webgl application sends the time of the packet, the
					users public ip address, and the site visited as described above.
				</p>
				<p>
					fortuna.wumbogames.com stores the user's email to define that user's
					account, and the salted hash of the user's password, encrypted with
					bcrypt. JWTs are used for session validation. HTTPS is used everywhere
					and your email will not be used for anything other than password resets, and
					email confirmation that are requested for your email address, and 
					for privacy policy updates in the unlikely event they occur. Your email
					will not be displayed to other users and is only used to for log-in purposes. 
					Your account name will be sent to other users on leaderboards and in battles.
				</p>
			</div>
		</div>
	);
	
}

export default PrivacyPolicy;
