//@flow strict

import * as React from 'react';
import WumboBanner from '../../global/WumboBanner';
import Navbar from '../../global/Navbar';
import BCPCResults from './BCPCResults.png';
import PictureAndDescription from '../../global/PictureAndDescription';
import VideoAndDescription from '../../global/VideoAndDescription';
import postSiteVisit from '../../global/postSiteVisit';
import '../../Main.css';

function BCPC1() {
	React.useEffect(() => postSiteVisit('/BCPC/BCPC1'), []);
	return (
		<div>
			<WumboBanner />
			<div className="maintext">
				<Navbar />
				<h1 className="title">1st Annual Brookfield Computer Programming Challenge</h1>
				<p>
					Congrats to all the teams who competed this year! Problem sets, an analysis of the 
					problems, problem solution code, solution videos, and final scores are all available here.
				</p>
				<ul className="normalList">
					<li> <a href="/BCPC/BCPC1/BCPCProblems.pdf" title="Download">2017 Problem Set download</a></li>
					<li> <a href="/BCPC/BCPC1/BonusProblem.pdf" title="Download">2017 Bonus Problem download</a></li>
					<li> <a href="/BCPC/BCPC1/ProblemsAnalysis.pdf" title="Download">2017 Problem Analysis</a></li>
					<li> <a href="/BCPC/BCPC1/realCompetitionTests.zip" title="Download">2017 Problem Test Cases</a></li>
					<li> <a href="/BCPC/BCPC1/solutions.zip" title="Download">2017 Solution Code</a></li>
				</ul>

				<br className="clearAll"/>

				<h2 className="title centeredText" >Final Standings</h2>
				<PictureAndDescription imageSrc={BCPCResults}>
					Congradulations to Brookfield East for placing 1st and 2nd, to Brookfield Central for 
					placing 3rd, and to Homestead for placeing 4th! Also, congrats to all teams for being 
					able to solve at least two problems!
				</PictureAndDescription>

				<br className="clearAll"/>

				<h2 className="title centeredText">Solution Videos</h2>
				<VideoAndDescription videoLink="https://www.youtube.com/embed/pp_HDzeRSeY">
					This is the introduction to the series.
				</VideoAndDescription>
				<VideoAndDescription videoLink="https://www.youtube.com/embed/Coe5eB6Ha28">
					Problem 1: Passing Bills. This was the input/output problem of the set. The problem 
					consisted of inputting two numbers and printing the first of them.
				</VideoAndDescription>
				<VideoAndDescription videoLink="https://www.youtube.com/embed/fPiFkQQw_g4">
					Problem 2: Apple-Pen. This was the string manipulation problem of the set. This problem
					required knowledge of text input using the java.util.Scanner class, as well as string
					concatination (the '+' operator for Strings), and either the substring method or the
					split method.
				</VideoAndDescription>
				<VideoAndDescription videoLink="https://www.youtube.com/embed/rVo8KxiH8oQ">
					Problem 3: The Halting Problem. This problem could have been solved in several ways, as
					discussed in the problem analysis. Valid solutions involved either using nested loops,
					or sorting and using a single loop. For those of you who may be inclined to fact-check
					me, I misspoke in the beginning of the video. The Halting Problem was proved unsolvable
					by Alan Turing, not Charles Babbage. I must have seen the Babbage's name in the first
					line of the problem and accidentally said it instead.
				</VideoAndDescription>
				<VideoAndDescription videoLink="https://www.youtube.com/embed/gfRDpwGJlh4">
					Problem 4: One. This was the obligatory problem concerning the random mathmatical properties
					of an arbitrary number, in particular, the number 1. In addition to several other properties,
					1 is the only natural number that is neither prime nor composite, so this problem required a
					program that could efficiently find whether a number was prime, composite, or neither.
					Because the input was very large, however, this required the program to be efficient in
					choosing which numbers to check.
				</VideoAndDescription>
				<VideoAndDescription videoLink="https://www.youtube.com/embed/ss_JLuX_95g">
					Problem 5: Negligent Norbert. This was the mathematical problem of the set, which required a
					program to efficiently count the minimum number of keystokes to type a given number of unique
					responses to clarification requests to a programming competition.
				</VideoAndDescription>
				<VideoAndDescription videoLink="https://www.youtube.com/embed/J2AMCJpxOhk">
					Problem 6: Calculatus Eliminatus. This problem involved finding the location of a missing 
					object by eliminating all of the places it couldn't be. One solution was based on a 
					decrease-and-conquere approach and involved combining ranges that were adjacent to each 
					other, while the another valid solution was based on the properties of ranges once they
					had been sorted in a certain manner.
				</VideoAndDescription>
				<VideoAndDescription videoLink="https://www.youtube.com/embed/OAclgfzxXKU">
					Problem 7: Bike Race. This was a graph theory problem that required finding the diameter
					of a graph. The algorythm used in the video and problem analysis is known as the 
					<a href="https://en.wikipedia.org/wiki/Floyd%E2%80%93Warshall_algorithm">
						Floyd-Warshal algorythm
					</a>
					, which along with 
					<a href="https://en.wikipedia.org/wiki/Dtra%27s_algorithm">
						Dijkstra's algortyhm
					</a>
					is incredibly helpful for solving most graph theory problems. While several teams got quite
					close, no teams were able to submit a correct solution to this problem during the competition.
				</VideoAndDescription>
				<VideoAndDescription videoLink="https://www.youtube.com/embed/NbPpOwTZrTY">
					Bonus Problem: LAST Robotics. This was the bonus problem of the set, which was only available
					to teams who solved the other problems before hand. This problem involved knowledge of either
					binary numbers or a nifty recursive discovery.
				</VideoAndDescription>

			</div>
		</div>
	);
}

export default BCPC1;
