//@flow strict

import {ReactNode} from 'react';

type Props = {
	videoLink: string,
	children: ReactNode,
};

function VideoAndDescription(props: Props) {
	return (
		<div>
			<div className="gamepanel">
				<div className="photo">
					<iframe 
						title={props.videoLink}
						width="560" 
						height="315" 
						src={props.videoLink}
						frameBorder="0" 
						allowFullScreen 
					/>
				</div>
				<div className="gamedescription">
					<p className="normal">
						{props.children}
					</p>
				</div>
			</div>
			<br className="clearAll"/>
		</div>
	);
}

export default VideoAndDescription;
