//@flow strict

import * as React from 'react';
import  {useState} from 'react';
import ColorButton from './ColorButton';
import Plot from './Plot';

import type {ColorType} from './ColorType.js';

type Props = {};
const colors: Array<ColorType> = [
    'roundedRedBox', 
    'roundedGreenBox', 
    'roundedBlueBox', 
    'roundedPinkBox', 
    'roundedPurpleBox',
];

type Vec =  {
    x: number;
    y: number;
    pointInLeft?: boolean;
}

function ColorSelector(props: Props) {
    const [selectedColor, setSelectedColor] = useState(colors[0]);  
    const [redPos, setRedPos] = useState<Vec|null>(null);
    const [bluePos, setBluePos] = useState<Vec|null>(null);
    const [greenPos, setGreenPos] = useState<Vec|null>(null);
    const [pinkPos, setPinkPos] = useState<Vec|null>(null);
    const [purplePos, setPurplePos] = useState<Vec|null>(null);  


    return <div>
        <div className="alignChildrenHorizontally">
            {
                colors.map(color => 
                    <ColorButton 
                        selected={selectedColor===color} 
                        color={color} 
                        key={color} 
                        onClick={() => setSelectedColor(color)}
                        />
                )
            }
        </div>
        <div>
            <Plot selectedColor={selectedColor} isLeft={true}
                redPos={redPos} setRedPos={setRedPos} 
                bluePos={bluePos} setBluePos={setBluePos} 
                greenPos={greenPos} setGreenPos={setGreenPos} 
                pinkPos={pinkPos} setPinkPos={setPinkPos} 
                purplePos={purplePos} setPurplePos={setPurplePos}
                />
            <Plot selectedColor={selectedColor} isLeft={false}
                redPos={redPos} setRedPos={setRedPos} 
                bluePos={bluePos} setBluePos={setBluePos} 
                greenPos={greenPos} setGreenPos={setGreenPos} 
                pinkPos={pinkPos} setPinkPos={setPinkPos} 
                purplePos={purplePos} setPurplePos={setPurplePos}
                />
        </div>
    </div>;
}

export default ColorSelector;