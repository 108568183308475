//@flow strict

import getRecentSubmissions from './getRecentSubmissions';
import createJobRequest from '../apiRequests/createJobRequest';
import JobRequest from '../jobs/JobRequest';
import {toast} from 'react-toastify';

import type {Verdict, Submission} from './getRecentSubmissions';
import type {JobInfo} from '../jobs/JobInfo';

const oldSubmissions: Array<number>=[];
let initialized=false;

function initCFChecker(handle: string): void {
	getRecentSubmissions(handle,
		100,
		submissions => {
			const graded=submissions.filter(submission => 
				submission.verdict!=null && 
				submission.verdict !== 'TESTING');
			if (graded.length === 0) {
				toast.error("Couldn't get submissions for handle: "+handle+'!');
				return;
			}
			for (const submit of graded) {
				oldSubmissions.push(submit.id);
			}
			initialized=true;
		}
	);
}

let updatesUntilNextRefresh=0;

function checkForSubmissionsPublic(handle: string, onNewResult: (submission: Submission) => void): void {
	if (!initialized) {
		console.log('still initializing...');
		return;
	}
	updatesUntilNextRefresh--;
	if (updatesUntilNextRefresh>0) {
		return;
	}
	updatesUntilNextRefresh=60; //and then set it to something lower when we get our results
	getRecentSubmissions(handle, 3, submissions => {
		const graded = submissions.filter(submission => submission.verdict != null && submission.verdict !== 'TESTING');
		if (graded.length<submissions.length) {
			//then something is currently testing so update sooner...
			updatesUntilNextRefresh=1;
		}
		else {
			updatesUntilNextRefresh=4;
		}
		if (graded.length===0) {
			console.log('nothing graded :(');
			return;
		}
		for (const submission of graded) {
			if (oldSubmissions.includes(submission.id)) {
				continue;
			}
			onNewResult(submission);
			oldSubmissions.push(submission.id);
		}
	});
}

function checkForNewSubmissions(password: string): void {
	checkForSubmissionsPublic('SecondThread', (submission: Submission) => {
		//otherwise add a job to announce this submission
		const problemIndex: string = submission.problem.index;
		const verdict: Verdict = submission.verdict ?? 'TESTING';
		const testsPassed: number = submission.passedTestCount;
		const now=new Date();
		const inTwoMinutes=new Date(now.getTime()+1000*60*2);
		const jobInfo: JobInfo = {
			problemIndex: problemIndex,
			verdict: verdict,
			testsPassed: testsPassed,
		}
		const toCreate = new JobRequest('cf_announce', now, inTwoMinutes, jobInfo);
		createJobRequest(toCreate, password);
	});
}

export {initCFChecker, checkForSubmissionsPublic};

export default checkForNewSubmissions;

