//@flow strict

import * as React from 'react';
import WumboBanner from '../../global/WumboBanner';
import Navbar from '../../global/Navbar';
import PictureAndDescription from '../../global/PictureAndDescription';
import TempusTitleScreen from './tempustitlescreen.png';
import TempusScreenshot1 from './tempusscreenshot1.png';
import TempusScreenshot2 from './tempusscreenshot2.png';
import TempusScreenshot4 from './tempusscreenshot4.png';
import postSiteVisit from '../../global/postSiteVisit';
import '../../Main.css';

const GAME_DOWNLOAD = '/games/tempus/tempus.jar';

function Tempus(): JSX.Element {
	React.useEffect(() => postSiteVisit('/games/Tempus'), []);
	return (
		<div>
			<WumboBanner />
			<div className="maintext">
				<Navbar />
				<PictureAndDescription imageSrc={TempusTitleScreen} imageDownload={GAME_DOWNLOAD}>
					Tempus is WiErD0 Games' (now Wumbo Games') second Ludum Dare entry, built for the theme,
					"The Future is Now". In it, you control time, meaning you can go into the future or past 
					to get to the finish.
					Tempus was programmed by Wumbo Games, and the art was made by Thome the Magician.
					<p className="normal">You can download Tempus for free below!</p>
				</PictureAndDescription>
				<div className="indented">
					<a href={GAME_DOWNLOAD} title="Download">Download Here!</a>
					<br className="clearAll"/>
					<br className="clearAll"/>
					<h2 className="title"><u>Screenshots:</u></h2>	
					<img src={TempusScreenshot1} width="80%" className="center" alt="" />
					<div className="photolist"><img src={TempusScreenshot2} width="400px" className="center" alt="" /></div>
					<div className="photolistright"><img src={TempusScreenshot4} width="400px" className="center" alt="" /></div>
					<div className="photolist"><img src={TempusScreenshot2} width="400px" className="center" alt="" /></div>
					<div className="photolistright"><img src={TempusScreenshot4} width="400px" className="center" alt="" /></div>
					<br className="clearAll"/>
				</div>
			</div>
		</div>
	);
}

export default Tempus;
