//@flow strict

import getJobRequests from './apiRequests/getJobRequests';
import getJokeFromAPI from './jokes/getJokeFromAPI';
import getUpcomingContests from './codeforcesAPI/getUpcomingContests';
import deleteJobRequests from './apiRequests/deleteJobRequest';
import {startAlarm, stopAlarm} from './videos/Alarm';
import {playReloadSound} from './videos/Reload';
import {playVictorySound} from './videos/Victory';
import JobRequest from './jobs/JobRequest';
import getWeatherDescription from './weather/getWeatherDescription';
import createJobRequest from './apiRequests/createJobRequest';
import parseStringForTime from './parseStringForTime';
import secondsToTime from './secondsToTime';
import playCompletedSound from './sound/playCompletedSound';

import type {Sounds} from './videos/Sounds';
import type {JobInfo} from './jobs/JobInfo';
import type {TextEvaluationResult} from './TextEvaluationResult';

let watchdogDate: Date|null = null;
let jobWaitingOn: JobRequest|null = null;

function tryAnsweringRequests(
	password: string, 
	sayString: (toSay: string, doAfter: (()=>void) | null, rate: number|null) => void,
	playMusic: (musicType: Sounds) => void,
	setListening: (shouldListen: boolean) => void,
	setScreenDim: (shouldScreenDim: boolean) => void,
	setVolume: (newVolume: number) => void,
) {
	if (watchdogDate != null) {
		const dTime=Date.now()-watchdogDate.getTime();
		if (dTime>1000*60*10) {
			//if it has been more than 10 minutes, kill the watchdog
			watchdogDate = null;
		}
	}
	if (watchdogDate != null) {
		return; //already handling something at the moment...
	}
	watchdogDate=new Date();
	getJobRequests(password, (requestsAll) => {
		const requests: Array<JobRequest> = requestsAll.filter(x => x.jobType !== 'cf_prep');
		if (requests.length===0) {
			//nothing to answer...
			watchdogDate=null;
			return;
		}
		else {
			const reqToAnswer: JobRequest=requests[0];
			if (new Date() > reqToAnswer.abandonTime) {
				const id=reqToAnswer._id;
				deleteJobRequests(password, id, () => {watchdogDate=null;});
				return;
			}
			console.log('Trying to handle request: ');
			console.log(reqToAnswer);
			if (reqToAnswer.jobType !== 'screen_dim' && reqToAnswer.jobType !== 'stop_listening') {
				setScreenDim(false);
			}
			let info: JobInfo|null = null;
			switch(reqToAnswer.jobType) {
				case 'cf_schedule':
					getUpcomingContests(string => sayString(string, () => {
						const id=reqToAnswer._id;
						deleteJobRequests(password, id, () => {watchdogDate=null;});
					}, null));
					break;
				case 'cf_prep':
					throw new Error('Cant handle cf_prep jobs on website!');
				case 'timer':
					info=reqToAnswer.jobInfo;
					if (info.reminderValue != null) {
						sayString('Sir you wanted to be reminded '+info.reminderValue, () => {
							startAlarm();
						}, null);
					}
					else {
						startAlarm();
					}
					jobWaitingOn=reqToAnswer;
					break;
				case 'music':
					info=reqToAnswer.jobInfo;
					if (info.musicType == null) {
						sayString('No music type was logged with the job request!', null, null);
					}
					else {
						playMusic(info.musicType);
					}
					const id=reqToAnswer._id;
					console.log('Trying to delete...');
					deleteJobRequests(password, id, () => {watchdogDate=null;});
					break;
				case 'joke':
					getJokeFromAPI(joke => sayString(
						joke, 
						() => {
							console.log(joke);
							const id=reqToAnswer._id;
							deleteJobRequests(password, id, () => {watchdogDate=null;});
						}, 
						0.9,
					));
					break;
				case 'weather':
					info=reqToAnswer.jobInfo;
					let daysFromNow=0;
					if (info.timeOfQuery!=null) {
						const now=new Date();
						const query=info.timeOfQuery;
						const utc1=Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());
						const utc2=Date.UTC(query.getFullYear(), query.getMonth(), query.getDate());
						const msPerDay=1000*60*60*24;
						daysFromNow=Math.round((utc2-utc1)/msPerDay);
					}
					getWeatherDescription(daysFromNow, description => {
						sayString(description, () => {
							deleteJobRequests(password, reqToAnswer._id, () => {watchdogDate=null;});
						}, null);
					});
					break;
				case 'awake_check':
					sayString('For you sir, always.', () => {
						deleteJobRequests(password, reqToAnswer._id, () => {watchdogDate=null;});
					}, null);
					break;
				case 'start_listening':
					setListening(true);
					sayString('Always a pleasure hearing you work sir.', () => {
						deleteJobRequests(password, reqToAnswer._id, () => {watchdogDate=null;});
					}, null);
					//Too many false positives here, only activate on saying 'ZERO'
					break;
				case 'stop_listening':
					setListening(false);
					sayString('Understood sir.', () => {
						deleteJobRequests(password, reqToAnswer._id, () => {watchdogDate=null;});
					}, null);
					break;
				case 'time_check':
					const now=new Date();
					let hours=now.getHours();
					const minutes=now.getMinutes();
					const AMPM=hours>11&&hours<24?'PM':'AM';
					hours%=12;
					if (hours===0) {
						hours=12;
					}
					let minutesText=minutes+'';
					if (minutes===0) {
						minutesText="O'clock";
					}
					else if (minutes<10) {
						minutesText='O'+minutes;
					}
					sayString("It's "+hours+':'+minutesText+' '+AMPM+'.', () => {
						deleteJobRequests(password, reqToAnswer._id, () => {watchdogDate=null;});
					}, null);
					break;
				case 'screen_dim':
					setScreenDim(true);
					sayString('Goodnight sir.', () => {
						deleteJobRequests(password, reqToAnswer._id, () => {watchdogDate=null;});
					}, null);
					break;
				case 'volume':
					info=reqToAnswer.jobInfo;
					if (info.newVolume != null) {
						const setTo=Math.max(0, Math.min(100, info.newVolume));
						setVolume(setTo);
						sayString('Volume set to '+Math.round(setTo)+'.', () => {
							deleteJobRequests(password, reqToAnswer._id, () => {watchdogDate=null;});
						}, null);
					}
					else {
						sayString('No job info specifying what volume to set to!', () => {
							deleteJobRequests(password, reqToAnswer._id, () => {watchdogDate=null;});
						}, null);
					}
					break;
				case 'cf_announce':
					info=reqToAnswer.jobInfo;
					if (info.problemIndex == null || info.verdict == null || info.testsPassed == null) {
						console.log('Info does not have problemIndex, verdict, and testsPassed!');
						console.log(info);
						deleteJobRequests(password, reqToAnswer._id, () => {watchdogDate=null;});
					}
					else {
						let toSay: string|null = null;
						const problemIndex: string = info.problemIndex;
						if (info.verdict === 'OK') {
							toSay='Well done sir! AC on problem '+problemIndex+'.';
							playVictorySound();
						}
						else {
							const verdictAsString=info.verdict.replace(/_/g, ' ');
							toSay='Problem '+problemIndex+' got '+verdictAsString+' test '+(info.testsPassed+1)+' sir.';
						}
						sayString(toSay, () => {
							deleteJobRequests(password, reqToAnswer._id, () => {watchdogDate=null;});
						}, null);
					}
					break;
				case 'ping':
					info=reqToAnswer.jobInfo;
					playCompletedSound();
					deleteJobRequests(password, reqToAnswer._id, () => {watchdogDate=null;});
					break;
				default:
					throw new Error('Unexpected job type! '+reqToAnswer.jobType);
			}
		}
	});
}

function onTimerSnooze(
	evaluation: TextEvaluationResult, 
	password: string,
	sayString: (toSay: string) => void,
): void {
	if (jobWaitingOn == null) {
		console.log('Nothing to snooze...');
		return;
	}
	const waitingOn=jobWaitingOn;
	const entities: any=evaluation.entities;
	let time: Date = new Date();
	if (entities['wit$datetime:datetime'] != null) {
		const ent=entities['wit$datetime:datetime'][0];
		if (ent.value != null) {
			time=new Date(ent.value);
		}
	}
	else if (entities['timer_length:timer_length'] != null) {
		const ent=entities['timer_length:timer_length'][0];
		if (ent.value!=null) {
			const value=ent.value;
			time=parseStringForTime(value);
		}
	}
	else {
		//just make it 20 minutes by default
		console.log('Didnt see the time, making it 20 minutes by default');
		time.setTime(time.getTime()+20*60*1000);
	}

	const dSeconds=(time.getTime()-Date.now())/1000;
	sayString('Postponed for '+secondsToTime(dSeconds)+'.');

	//forget 3000 days later
	const later=new Date(time.getTime()+60*60*24*3000*1000);
	waitingOn.activationTime=time;
	waitingOn.abandonTime=later;
	stopAlarm();
	deleteJobRequests(password, waitingOn._id, () => {
		watchdogDate=null;
		jobWaitingOn=null;

		//then create a new job request to be reminded later
		createJobRequest(waitingOn, password);
	});
}

function onTimerCompleted(
	evaluation: TextEvaluationResult, 
	password: string
): void {
	if (jobWaitingOn == null) {
		console.log('Nothing to snooze...');
		return;
	}
	stopAlarm();
	deleteJobRequests(password, jobWaitingOn._id, () => {
		watchdogDate=null;
		jobWaitingOn=null;
		playReloadSound();
	});
}

export {onTimerSnooze, onTimerCompleted};

export default tryAnsweringRequests;
