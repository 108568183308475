//@flow strict

import {ReactNode} from 'react';

type Props = {
	imageSrc: string,
	imageDownload?: string,
	children: ReactNode,
};

function PictureAndDescription(props: Props) {
	return (
		<div>
			<div className="gamepanel">
				<div className="photo">
					{
						props.imageDownload == null?
							<img 
								src={props.imageSrc} 
								alt=""
								width="560" 
							/>
						:
							<a href={props.imageDownload} title="Download">
								<img 
									src={props.imageSrc} 
									alt=""
									width="560" 
								/>
							</a>
					}
				</div>
				<div className="gamedescription">
					<p className="normal">
						{props.children}
					</p>
				</div>
			</div>
			<br className="clearAll"/>
		</div>
	);

}

export default PictureAndDescription;
