//@flow strict

import * as React from 'react';
import banner from './wumbogamesbanner.png';

function WumboBanner() {
	return (
		<div>
			<img src={banner} alt="" width="100%" className="center limitedMaxWidth"/>
		</div>
	);
}

export default WumboBanner;
