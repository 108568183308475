//@flow strict

import type {TextEvaluationResult} from '../TextEvaluationResult';

const CLIENT_TOKEN = 'BSGMZQ67SZ3CC5XHFOURRR4TGKUA7SR3';

function evaluateTextForMeaning(text: string, onLoad: (textEvalRes: TextEvaluationResult) => void): void {
	let realText=text.toLowerCase().replace(/christmas/g, 'needle');
	
	//kind of hacky way of fixing the 'set a 3-minute timer' not working correctly because it
	//doesn't process '3-minutes' as '3 minutes'
	realText=realText.toLowerCase().replace(/-/g, ' ');

	const uri='https://api.wit.ai/message?v=20200528&q='+realText;
	const auth='Bearer '+CLIENT_TOKEN;

	fetch(uri, {headers: {Authorization: auth}}).then(res => {
		res.json().then(data => {
			onLoad(data);
		});
	});
}

export default evaluateTextForMeaning;
