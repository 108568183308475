//@flow strict

import * as React from 'react';
import WumboBanner from '../global/WumboBanner';
import Navbar from '../global/Navbar';
import PictureAndDescription from '../global/PictureAndDescription';
import UnderTitlescreen from './under/undertitlescreen.png';
import CrampedTitlescreen from './cramped/crampedtitlescreen.png';
import TempusTitlescreen from './tempus/tempustitlescreen.png';
import ClueTitlescreen from './clue/Screenshot1.png';
import ColarityScreenshot3 from './colarity/colaritytitlescreen.png';
import postSiteVisit from '../global/postSiteVisit';
import '../Main.css';

function Games() {
	React.useEffect(() => {
		postSiteVisit('/games');
	}, []);
	return (
		<div>
			<WumboBanner />
			<div className="maintext">
				<Navbar />
				<h1 className="title">Games</h1>
				<PictureAndDescription imageSrc={ColarityScreenshot3} imageDownload={"/Games/Colarity"}>
					Colarity is a puzzle-combat game made by David Harmeyer, Joshua Fair, and Mary McIntosh.
					Play it in your browser at wumbogames.com!
				</PictureAndDescription>
				<PictureAndDescription imageSrc={ClueTitlescreen} imageDownload={"/Games/Clue"}>
					Clue is a two-player puzzle game inspired by the classic board game in which one player
					is the murderer and the other is the detective, but entirely in one room.
				</PictureAndDescription>
				<PictureAndDescription imageSrc={CrampedTitlescreen} imageDownload={"/Games/Cramped"}>
					Cramped is a puzzle platformer where the main goal is to get back to your igloo. However,
					you can't play until you build the level. Once you fit all the pieces onto the screen, 
					you can play through and make it to the igloo.
				</PictureAndDescription>
				<PictureAndDescription imageSrc={TempusTitlescreen} imageDownload={"/Games/Tempus"}>
					Tempus is a game made for a mini <a href="http://ludumdare.com/compo/" >Ludum Dare
					</a> competition with the theme:
					<i>The Future is Now</i>. In it, you control time, and can into the future or past to beat levels.
				</PictureAndDescription>
				<PictureAndDescription imageSrc={UnderTitlescreen} imageDownload={"/Games/Under"}>
					Under-Life of a Frog, is a game made (again) for a 
					<a href="http://ludumdare.com/compo/">Ludum Dare</a> competition.
					It was the first game made by WiErD0 Games (now Wumbo Games) with Java, and more
					of an experiment than anything else. It features random, infinite terrain
					generation, huge collision boxes, and a whole lot of useless code. Enjoy!
				</PictureAndDescription>
			</div>
		</div>
	);
}

export default Games;
