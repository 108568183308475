//@flow strict

import * as React from 'react';
import WumboBanner from '../../global/WumboBanner';
import Navbar from '../../global/Navbar';
import PictureAndDescription from '../../global/PictureAndDescription';
import ClueScreenshot1 from './Screenshot1.png';
import ClueScreenshot2 from './Screenshot2.png';
import ClueScreenshot3 from './Screenshot3.png';
import ClueScreenshot4 from './Screenshot4.png';
import ClueScreenshot5 from './Screenshot5.png';
import postSiteVisit from '../../global/postSiteVisit';
import '../../Main.css';

const GAME_DOWNLOAD = '/games/clue/Clue.jar';

function Clue(): JSX.Element {
	React.useEffect(() => postSiteVisit('/games/Clue'), []);

	return (
		<div>
			<WumboBanner />
			<div className="maintext">
				<Navbar />
				<PictureAndDescription imageSrc={ClueScreenshot1} imageDownload={GAME_DOWNLOAD}>
					Clue is Wumbo Games' entry for Ludum Dare 37. It is a two-player game in which one player
					is the murderer who needs to kill the other player, and one player needs to discover
					who is the murderer, what weapon they used, and what room they are in.
					<p>
						Conveniently, the theme of Ludum Dare 37 was "One Room", which makes determining the room
						rather easy. Good luck!
					</p>
				</PictureAndDescription>
				<div className="indented">
					<a href={GAME_DOWNLOAD} title="Download">Download Here!</a>
					<br />
					<br />
					<h2 className="title"><u>Screenshots:</u></h2>	
					<img src={ClueScreenshot1} width="80%" className="center" alt=""/>
					<div className="photolist"><img src={ClueScreenshot2} width="400px" className="center" alt=""/></div>
					<div className="photolistright"><img src={ClueScreenshot3} width="400px" className="center" alt=""/></div>
					<br />
					<div className="photolist"><img src={ClueScreenshot4} width="400px" className="center" alt=""/></div>
					<div className="photolistright"><img src={ClueScreenshot5} width="400px" className="center" alt=""/></div>
					<br className="clearAll"/>
				</div>
			</div>
		</div>
	);
}

export default Clue;
