//@flow strict

function stringToNumber(toProcess: string): number {
	const halfAdd=(toProcess.includes('1/2') || toProcess.includes('half'));
	if (halfAdd) {
		return 0.5;
	}
	const words = [
		"zero", "one", "two", "three", "founr", "five", "six", "seven", "eight", "nine", "ten"
	];
	for (let i=0; i<words.length; i++) {
		if (words[i]===toProcess)  {
			return i;
		}
	}
	const parsed=Number(toProcess);
	if (isNaN(parsed)) {
		return 0;
	}
	return parsed;
}

//approach: get time units. If there is only one, remove the time units and process everything else for numbers
//if there are multiple time units, take the thing before each time unit
function parseStringForTime(toProcess: string): Date {
	let secondsToAdd=0;
	const words=toProcess.split(' ');
	let nUnits=0;
	let unit=60;
	for (const word of words) {
		if (word.includes('minute') || word.includes('hour') || word.includes('second') || word.includes('day')) {
			nUnits++;
		}
		if (word.includes('second')) {
			unit=1;
		}
		if (word.includes('minute')) {
			unit=60;
		}
		if (word.includes('hour')) {
			unit=60*60;
		}
		if (word.includes('day')) {
			unit=60*60*24;
		}
	}
	if (nUnits===0) {
		console.log('No units! assuming minutes...');
		nUnits=1;
		unit=60;
	}
	if (nUnits===1) {
		let haveA=false;
		for (const word of words) {
			if (word === 'a' || word === 'an') {
				haveA=true;
			}
			else {
				secondsToAdd+=unit*stringToNumber(word);
			}
		}
		if (haveA) {
			secondsToAdd+=unit;
		}
	}
	else {
		//multiple units, when I hit a unit, add in everything before
		let haveA=false;
		let curTotal=0
		for (const word of words) {
			if (word.includes('minute') || word.includes('hour') || word.includes('second') || word.includes('day')) {
				if (word.includes('second')) {
					unit=1;
				}
				if (word.includes('minute')) {
					unit=60;
				}
				if (word.includes('hour')) {
					unit=60*60;
				}
				if (word.includes('day')) {
					unit=60*60*24;
				}
				if (haveA) {
					curTotal++;
				}
				secondsToAdd += unit*curTotal;
				curTotal=0;
				haveA=false;
				continue;
			}
			if (word=== 'a' || word === 'an') {
				haveA=true;
			}
			else {
				curTotal+=stringToNumber(word);
			}
		}
	}
	const date=new Date();
	date.setTime(date.getTime()+secondsToAdd*1000);
	return date;
}

export default parseStringForTime;

export {stringToNumber};
