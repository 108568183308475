//@flow strict

/*
curl 'https://www.googleapis.com/youtube/v3/search?part=snippet&q=skateboarding%20dog&type=video&key=AIzaSyB1uEHqkV-25Y9D8O6bjPkIB4DO-ci3PSw' --header 'Accept: application/json'
*/
function ytSearchAPICall(queryString: string, onLoad: (videoId: string) => void): void {
	const apiKey='AIzaSyB1uEHqkV-25Y9D8O6bjPkIB4DO-ci3PSw';
	console.log('Making api call!');
	fetch('https://www.googleapis.com/youtube/v3/search'+
		'?part=snippet'+
		'&q='+queryString.split(' ').join('%20')+
		'&type=video'+
		'&maxResults=1'+
		'&key='+apiKey
	).then(res => {
		res.json().then(data => {
			console.log(data);
			if (data.items == null) {
				console.log('Looks like we exceeded our YT quota :(');
				return;
			}
			const videoId=data.items[0].id.videoId;
			onLoad(videoId);
		});
	});
}

export default ytSearchAPICall;
