//@flow strict

import {toast} from 'react-toastify';

type RoundResult = {
	roundId: string,
	roundName: string,
	userWon: boolean,
	precontestLGMRating: number,
}

type UserQueryResult = any;/*{
	[string]: Array<RoundResult>,
}*/

type AllUsersResult = any;/*{
	[string]: UserQueryResult,
}*/

type UserRating = {
	handle: string,
	rating: number,
}

function getLGMMatchupResults(userHandles: Array<string>, onLoad: (
	res: AllUsersResult, 
	allRatings: Array<UserRating>
) => void): void {
	fetch('/api/didIBeatTourist/getLGMMatchupResults', {
		method: 'POST',
		headers: {
			'Access-Control-Allow-Origin': '*',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			userHandles: userHandles
		}),
	}).then(res => {
		if (res.status!==200) {
			toast.error("Error connecting to server! Please try again or check console for more info.");
			console.log('Call failed!');
			console.log(res.status);
			console.log(res);
		}
		else {
			res.json().then(data => {
				const lgmMatchupsList=data.lgmMatchupsList;
				const userRatings: Array<UserRating> = data.userRatings;
				console.log('Got data: ');
				console.log(data);
				const toReturn: AllUsersResult={};
				for (let i=0; i<userHandles.length; i++) {
					const userResult: UserQueryResult = lgmMatchupsList[i];
					toReturn[userHandles[i]] = userResult;
				}
				onLoad(toReturn, userRatings);
			});
		}
	});
}

export type {AllUsersResult, UserQueryResult, RoundResult, UserRating};

export default getLGMMatchupResults;
