//@flow strict

import * as React from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import Center from './Center.png';
import Middle from './Middle.png';
import Outer from './Outer.png';
import useInterval from 'react-useinterval';

type Props = {
	slow: boolean,
	listening: boolean,
};

const center = new Image();
const middle = new Image();
const outer = new Image();
center.src = Center;
middle.src = Middle;
outer.src = Outer;

function drawImage(ctx: CanvasRenderingContext2D, image: HTMLImageElement, x: number, y: number, width: number, angle: number=0) {
	ctx.translate(x, y);
	ctx.rotate(angle);
	ctx.drawImage(image, -width/2, -width/2, width, width);
	ctx.rotate(-angle);
	ctx.translate(-x, -y);
}

function lerp(a: number, b: number, time: number) {
	return b*time+a*(1-time);
}

function update(
	props: Props, 
	canvasRef: React.MutableRefObject<HTMLCanvasElement | null>,
	middleAngle: number,
	setMiddleAngle: React.Dispatch<React.SetStateAction<number>>,
	outerAngle: number,
	setOuterAngle: React.Dispatch<React.SetStateAction<number>>,
	innerScale: number,
	setInnerScale: React.Dispatch<React.SetStateAction<number>>,
	middleScale: number,
	setMiddleScale: React.Dispatch<React.SetStateAction<number>>,
	outerScale: number,
	setOuterScale: React.Dispatch<React.SetStateAction<number>>,
): void {

	const canvas=canvasRef.current;
	if (canvas==null) {
		return;
	}

	const ctx = canvas.getContext("2d");
	if (ctx==null) {
		console.log('Canvas context is somehow null... I dont think this should ever happen');
		return;
	}
	ctx.fillStyle='black';
	ctx.fillRect(0, 0, 1000000, 1000000);
	const width=950;
	const canvasWidth=800;

	const {slow}=props;

	if (props.listening || !props.slow) {
		setMiddleAngle((middleAngle+0.008)%(Math.PI*2));
		setOuterAngle((outerAngle-0.01)%(Math.PI*2));
	}
	const middleMultiplier=1+(slow?0:0.1)*Math.sin(20*middleAngle);

	const slowMultiplier=slow?0.78:1;
	const slowMultiplier2=slow?0.6:1;

	const targetInnerScale=width;
	const targetMiddleScale=width*middleMultiplier*slowMultiplier;
	const targetOuterScale=width*slowMultiplier2;

	setInnerScale(lerp(innerScale, targetInnerScale, 0.1));
	setMiddleScale(lerp(middleScale, targetMiddleScale, 0.2));
	setOuterScale(lerp(outerScale, targetOuterScale, 0.2));

	drawImage(ctx, center, canvasWidth/2, canvasWidth/2, innerScale);
	drawImage(ctx, middle, canvasWidth/2, canvasWidth/2, middleScale, middleAngle);
	drawImage(ctx, outer, canvasWidth/2, canvasWidth/2, outerScale, outerAngle);
}

function ZeroAnimation(props: Props) {

	const [middleAngle, setMiddleAngle] = useState(0);
	const [outerAngle, setOuterAngle] = useState(0);
	const [innerScale, setInnerScale] = useState(0);
	const [middleScale, setMiddleScale] = useState(0);
	const [outerScale, setOuterScale] = useState(0);
	const canvasRef = useRef<HTMLCanvasElement | null>(null);

	//TODO: call update 30 times per second
	useInterval(() => update(props, canvasRef, middleAngle, setMiddleAngle, outerAngle, setOuterAngle, innerScale, setInnerScale, middleScale, setMiddleScale, outerScale, setOuterScale), 1000/30);

	return (
		<canvas width="800px" height="800px" className="center" ref={canvasRef}/>
	);
}

export default ZeroAnimation;
