//@flow strict

import * as React from 'react';
import WumboBanner from '../../global/WumboBanner';
import Navbar from '../../global/Navbar';
import ColorSelector from './ColorSelector';


function PointLineDuality() {
    return(
		<div>
			<WumboBanner />
			<div className="maintext">
				<Navbar />
				<h1 className="title">Point Line Duality Demo</h1>
				<ColorSelector/>
			</div>
		</div>
	);
}

export default PointLineDuality;