//@flow strict

//$FlowFixMe flow doesn't see this file for some reason...
const perryMP3= require('./perryLoud.mp3');

let perryAlarm: NodeJS.Timeout | null = null;

const audio=new Audio(perryMP3.default);

function runAlarm() {
	audio.play();
}

function startAlarm() {
	audio.pause();
	audio.currentTime=0;
	perryAlarm = setInterval(runAlarm, 2500);
}

function stopAlarm() {
	if (perryAlarm != null) {
		clearInterval(perryAlarm);
		perryAlarm=null;
	}
}

export {startAlarm, stopAlarm};
